/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import ReusableModal from "./ReusableModal";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import AuthContext from "../../../../utils/secure-route/AuthContext";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import "./Settings.css";

function OtherIncomeType() {
  let { user } = useContext(AuthContext);
  const storeId = user.store;
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [incomes, setIncomes] = useState([]);
  const [editIncome, setEditIncome] = useState(null);
  const { Get, Post, Put, Delete } = useApi();
  useEffect(() => {
    Get("otherIncomeData")
      .then((response) => {
        setIncomes(response);
      })
      .catch((error) => {
        console.error("Error fetching income types:", error);
        toast.error("Failed to fetch income types.");
      });
  }, []);
  const handleShow = (income = null) => {
    if (income) {
      setEditIncome(income);
      setName(income.title); // Set name to the income title for editing
    } else {
      setEditIncome(null);
      setName(""); // Reset name when adding a new income type
    }
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
    setName(""); // Reset name when closing
    setEditIncome(null); // Reset editIncome when closing
  };
  const handleSave = async () => {
    if (name) {
      const data = {
        title: name,
        store: storeId,
      };

      if (editIncome) {
        // Updating an existing income type
        Put("otherIncomeData", editIncome.id, data)
          .then((response) => {
            const updatedIncome = response.data;
            setIncomes((prevIncomes) =>
              prevIncomes.map((income) =>
                income.id === updatedIncome.id ? updatedIncome : income
              )
            );
            toast.success("Income type updated successfully.");
            handleClose();
          })
          .catch((error) => {
            console.error("Error updating income type:", error);
            toast.error("Failed to update income type.");
          });
      } else {
        // Adding a new income type
        Post("otherIncomeData", data)
          .then((response) => {
            const newIncome = response.data;
            setIncomes([...incomes, newIncome]);
            toast.success("Income type added successfully.");
            handleClose();
          })
          .catch((error) => {
            console.error("Error saving income type:", error);
            toast.error("Failed to save income type.");
          });
      }
    } else {
      toast.warn("Income type name cannot be empty.");
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (!result.isConfirmed) return;

      Delete("otherIncomeData", id)
        .then(() => {
          setIncomes((prevIncomes) =>
            prevIncomes.filter((income) => income.id !== id)
          );
          toast.success("Income type deleted successfully.");
        })
        .catch((error) => {
          console.error(
            "Error deleting income:",
            error.response ? error.response.data : error.message
          );
          toast.error("Failed to delete income type.");
        });
    });
  };

  return (
    <>
      <div className="due-days">
        <div className="header-row">
          <div className="setting-title">Other Income Type</div>
          <div className="btn-position">
            <button className="vendor-add-button" onClick={() => handleShow()}>
              + Add New
            </button>
          </div>
        </div>
        <div className="dept-cards-row">
          {incomes.length === 0 ? (
            <p
              style={{
                textAlign: "center",
                fontFamily: "Manrope",
                fontWeight: "600",
                fontSize: "40px",
                background:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0.1) -92.86%, #4545DB 71.43%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              No data found
            </p>
          ) : (
            incomes.map((income) => (
              <div className="dept-card" key={income.id}>
                <input className="dept-info" value={income.title} readOnly />
                <svg
                  className="dept-icon"
                  onClick={() => handleShow(income)}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5854 4.695C13.8304 4.435 13.8304 4.00167 13.5854 3.755L12.1154 2.195C11.8829 1.935 11.4746 1.935 11.2296 2.195L10.0736 3.415L12.4295 5.915M2.45947 11.5017V14.0017H4.81534L11.7636 6.62167L9.4077 4.12167L2.45947 11.5017Z"
                    fill="#002300"
                  />
                </svg>
                <svg
                  className="dept-icon"
                  onClick={() => handleDelete(income.id)} // Show modal for editing
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.84503 12.6667C3.84503 13.4 4.41044 14 5.10149 14H10.1273C10.8184 14 11.3838 13.4 11.3838 12.6667V6C11.3838 5.26667 10.8184 4.66667 10.1273 4.66667H5.10149C4.41044 4.66667 3.84503 5.26667 3.84503 6V12.6667ZM11.3838 2.66667H9.81322L9.36718 2.19333C9.25409 2.07333 9.09075 2 8.92741 2H6.30141C6.13807 2 5.97473 2.07333 5.86165 2.19333L5.4156 2.66667H3.84503C3.4995 2.66667 3.2168 2.96667 3.2168 3.33333C3.2168 3.7 3.4995 4 3.84503 4H11.3838C11.7293 4 12.012 3.7 12.012 3.33333C12.012 2.96667 11.7293 2.66667 11.3838 2.66667Z"
                    fill="#002300"
                  />
                </svg>
              </div>
            ))
          )}
        </div>
      </div>
      <ReusableModal
        show={showModal}
        handleClose={handleClose}
        title={editIncome ? "Edit Other Income Type" : "Add Other Income Type"}
        width="660px"
        height="190px"
      >
        <form>
          <div
            className="form-group d-flex align-items-center mt-1"
            style={{ marginLeft: "10px" }}
          >
            <input
              type="text"
              id="incomeTypeName"
              className="input-field"
              placeholder="Enter income type name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{ width: "483px", marginRight: "20px" }}
            />
            <button
              onClick={handleSave}
              type="button"
              style={{
                padding: "6px 8px",
                backgroundColor: "#4545db", // Bootstrap primary color
                color: "white",
                border: "none",
                borderRadius: "40px",
                cursor: "pointer",
                width: "103px",
                height: "42px",
                fontSize: "18px",
              }}
            >
              {editIncome ? "Update" : "Save"}
            </button>
          </div>
        </form>
      </ReusableModal>
      <ToastContainer />
    </>
  );
}
export default OtherIncomeType;
