/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import useAPI from '../../../../utils/api-manager/Helper/useApi';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend,  } from 'chart.js';
import { DatePicker, Space, Typography, Empty } from 'antd';

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const { RangePicker } = DatePicker;
const { Title: AntdTitle } = Typography;

export default function StackedBarChart() {
  const [dateRange, setDateRange] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const chartRef = useRef();

  const { Post } = useAPI();

  // Add window resize listener
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchDashboardData = async (startDate, endDate) => {
    try {
      const payload = {
        start_date: startDate,
        end_date: endDate,
      };
      const response = await Post('dashboardChart', payload);
      const data = response.data;
      const businessReports = data.business_reports || [];
      const lotteryReports = data.lottery_reports || [];
      const mappedData = businessReports.map((entry, index) => ({
        name: entry.date,
        outflow: parseFloat(entry.cash_to_account || 0),
        inflow: parseFloat(lotteryReports[index]?.total_balance || 0),
      }));
      const filtered = mappedData.filter((entry) => {
        const entryDate = new Date(entry.name).toLocaleString('en-US', { timeZone: 'America/New_York' });
        const startDateObj = new Date(startDate).toLocaleString('en-US', { timeZone: 'America/New_York' });
        const endDateObj = new Date(endDate).toLocaleString('en-US', { timeZone: 'America/New_York' });
        return new Date(entryDate) >= new Date(startDateObj) && new Date(entryDate) <= new Date(endDateObj);
      });
      if (filtered.length === 0) {
        setFilteredData([]); 
      } else {
        setFilteredData(filtered);
      }
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setFilteredData([]); 
    }
  };
  
  const handleDateChange = (dates) => {
    setDateRange(dates);
    if (!dates || dates.length === 0) {
      setFilteredData([]); 
    } else if (dates.length === 2) {
      const [start, end] = dates;
      fetchDashboardData(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
    }
  };

  useEffect(() => {
    if (dateRange && dateRange.length === 2) {
      const [start, end] = dateRange;
      fetchDashboardData(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
    }
  }, [dateRange]);

  const netCashFlow = filteredData.reduce((acc, curr) => acc + curr.outflow + curr.inflow, 0);
  const formattedCashFlow = netCashFlow.toLocaleString();
  const totalOutflow = filteredData.reduce((acc, curr) => acc + curr.inflow, 0);
  const profitPercentage = totalOutflow !== 0 ? ((netCashFlow / totalOutflow) * 100).toFixed(2) : 0;
  const arrow = profitPercentage > 0 ? '↗' : profitPercentage < 0 ? '↓' : '↘';
  const arrowColor = profitPercentage > 0 ? 'green' : profitPercentage < 0 ? 'red' : 'gray';

  const data = {
    labels: filteredData.map((entry) => entry.name),
    datasets: [
      {
        label: 'Business Report',
        stack: 'positive', 
        data: filteredData.map((entry) => entry.outflow),
        backgroundColor: (context) => {
          const { ctx, chartArea } = context.chart;
          if (!chartArea) return null;

          const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
          gradient.addColorStop(0.02, 'rgba(255, 255, 255, 0.4)');
          gradient.addColorStop(0.90, 'rgba(0, 0, 255, 0)');
          gradient.addColorStop(0.30, 'rgba(0, 0, 255, 0.1)');
          gradient.addColorStop(0.48, 'rgba(0, 0, 255, 0.03)');
          gradient.addColorStop(0.95, 'rgba(0, 0, 255, 0.0)');
          return gradient;
        },
        borderColor: 'blue',
        borderWidth: {
          top: 2,
          left: 0,
          right: 0,
          bottom: 0,
        },
        hoverBackgroundColor: 'rgba(0, 0, 255, 0.5)',
        hoverBorderWidth: 0,
      },
      {
        label: 'Lottery Report',
        stack: 'negative', 
        data: filteredData.map((entry) => -entry.inflow),
        backgroundColor: (context) => {
          const { ctx, chartArea } = context.chart;
          if (!chartArea) return null;

          const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
          gradient.addColorStop(0.0, 'rgba(255, 255, 255, 0)');
          gradient.addColorStop(0.02, 'rgba(160, 160, 160, 0.2)');
          gradient.addColorStop(0.59, 'rgba(160, 160, 160, 0.03)');
          gradient.addColorStop(0.95, 'rgba(160, 160, 160, 0.2)');

          return gradient;
        },
        borderColor: 'black',
        borderWidth: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 2,
        },
        hoverBackgroundColor: 'rgba(0, 0, 0, 0.7)',
        hoverBorderWidth: 0,
      },
    ],
  };

  // Responsive options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: ({ raw, dataset }) => `${dataset.label}: $${Math.abs(raw)}`,
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          maxRotation: windowWidth < 768 ? 90 : 45,
          minRotation: 0,
          autoSkip: true,
          maxTicksLimit: windowWidth < 768 ? 5 : windowWidth < 992 ? 8 : 10,
          callback: function(value, index, values) {
            const label = this.getLabelForValue(value);
            if (windowWidth < 576) {
              return label.split('-')[2]; // Just the day
            } else if (windowWidth < 992) {
              const parts = label.split('-');
              return `${parts[1]}/${parts[2]}`; // MM/DD format
            }
            return label; // Full date on larger screens
          }
        }
      },
      y: {
        beginAtZero: true,
        stacked: true,
        ticks: {
          callback: (value) => value === 0 ? '0' : `$${Math.abs(value)}k`,
        },
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  };

  // Responsive styles based on window width
  const containerStyle = {
    padding: windowWidth < 768 ? '15px' : '20px',
    width: windowWidth < 768 ? '100%' : windowWidth < 992 ? '100%' : '900px', // Set width for different breakpoints
    marginRight: '20px',
    backgroundColor: 'white',
    borderRadius: '10px',
    height: windowWidth < 768 ? 'auto' : '520px',
    minHeight: windowWidth < 768 ? '350px' : '500px',
    boxSizing: 'border-box',
  };

  const headerStyle = {
    display: 'flex',
    flexDirection: windowWidth < 768 ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: windowWidth < 768 ? 'flex-start' : 'center',
    marginBottom: windowWidth < 768 ? '15px' : '10px',
  };

  const cashflowStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: windowWidth < 768 ? '15px' : '0',
  };

  const datePickerStyle = {
    width: windowWidth < 576 ? '100%' : 'auto',
    marginTop: windowWidth < 768 ? '10px' : '0',
  };

  const annotationStyles = {
    topAnnotation: { 
      position: 'absolute', 
      top: windowWidth < 768 ? '-10%' : '-5%', 
      left: windowWidth < 992 ? '50%' : '53%', 
      transform: 'translateX(-50%)', 
      fontWeight: 'bold', 
      fontSize: windowWidth < 768 ? '12px' : '16px', 
      color: 'blue' 
    },
    bottomAnnotation: { 
      position: 'absolute', 
      bottom: windowWidth < 768 ? '-8%' : '-3%', 
      left: windowWidth < 992 ? '50%' : '53%', 
      transform: 'translateX(-50%)', 
      fontWeight: 'bold', 
      fontSize: windowWidth < 768 ? '12px' : '16px', 
      color: 'black' 
    }
  };

  const customAnnotations = (
    <>
      <div style={annotationStyles.topAnnotation}>
        Business Report
      </div>
      <div style={annotationStyles.bottomAnnotation}>
        Lottery
      </div>
    </>
  );

  const chartContainerStyle = {
    position: 'relative',
    width: windowWidth < 576 ? '100%' : '80%',
    height: windowWidth < 768 ? '300px' : '400px',
    margin: '0 auto',
  };

  const emptyStateStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: windowWidth < 768 ? '250px' : '400px',
  };

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        <div style={cashflowStyle}>
          <AntdTitle level={2} style={{ fontSize: windowWidth < 768 ? '18px' : '20px', marginBottom: '10px' }}>
            Cashflow:
          </AntdTitle>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <span style={{ 
              color: netCashFlow >= 0 ? 'black' : 'green', 
              fontSize: windowWidth < 768 ? '24px' : '30px', 
              marginRight: '10px' 
            }}>
              ${formattedCashFlow}
            </span>
            <span style={{ 
              fontSize: windowWidth < 768 ? '12px' : '14px', 
              fontWeight: 'bold', 
              color: arrowColor 
            }}>
              <span style={{ fontSize: windowWidth < 768 ? '14px' : '16px' }}>{arrow}</span>
              {profitPercentage}%
            </span>
          </div>
        </div>
        <Space direction={windowWidth < 576 ? 'vertical' : 'horizontal'} style={datePickerStyle}>
          <RangePicker 
            onChange={handleDateChange}
            style={{ width: windowWidth < 576 ? '100%' : 'auto' }}
          />
        </Space>
      </div>
      
      {filteredData.length > 0 ? (
        <div style={chartContainerStyle}>
          {customAnnotations}
          <Chart type='bar' ref={chartRef} data={data} options={options} />
        </div>
      ) : (
        <div style={emptyStateStyle}>
          <Empty description="No data available for the selected date range" />
        </div>
      )}
    </div>
  );
}