/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../utils/secure-route/AuthContext";
import CustomInput from "../../custominput/CustomInput";
import "./LoginPage.css"

const LoginPage = () => {
  const { loginUser } = useContext(AuthContext);
  const { authToken } = useContext(AuthContext);
  const navigate = useNavigate();

  const checkLoggedIn = () => {
    if (authToken) {
      navigate("/");
    }
  };

  useEffect(() => {
    checkLoggedIn();
  }, [authToken]);

  const [errors, setErrors] = useState({});
  const [customErr, setCustomErr] = useState(false);
  const [customErrMsg, setCustomErrMsg] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const checkValidUsername = (username) => {
    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(username)) {
      setErrors((prevErrors) => ({ ...prevErrors, username: true }));
      setCustomErr(true);
      setCustomErrMsg("Please Enter a Valid Email as Username!");
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, username: "" }));
      setCustomErrMsg("");
    }
  };

  const [passError, setPassError] = useState(false);
  const [passErrMsg, setPassErrMsg] = useState("");

  const checkValidPassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>_\-])[A-Za-z\d!@#$%^&*(),.?":{}|<>_\-]{8,}$/;;
    if (!passwordRegex.test(password)) {
      setErrors((prevErrors) => ({ ...prevErrors, password: true }));
      setPassError(true);
      setPassErrMsg(
        "Password must be at least 8 characters, with one uppercase letter, one digit, and one special character."
      );
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, password: "" }));
      setPassErrMsg("");
    }
  };

  return (
    <div className=" account-creation-steps">
    <div className="row justify-content-center align-items-center">
      <p className="form-title">Welcome Back</p>
      <p className="form-subtitle">
        Ready to manage your ledger? Sign in to track your transactions and stay on top of your financials!
      </p>
      <form onSubmit={loginUser}>
        <div className="mb-3">
          <CustomInput
            type="text"
            id="username"
            name="username"
            placeholder="Enter Email"
            onBlur={(e) => {
              checkValidUsername(e.target.value);
            }}
            custom={customErr}
            msg={customErrMsg}
            required
            error={Boolean(errors.username)}
            helpertext={errors.username || usernameError}
          />
        </div>
        <div className="mb-3">
          <CustomInput
            type="password"
            id="password"
            placeholder="Password"
            required
            onBlur={(e) => {
              checkValidPassword(e.target.value);
            }}
            custom={passError}
            msg={passErrMsg}
            error={Boolean(errors.password)}
            helpertext={errors.password || passwordError}
          />
        </div>
        <div className="login-options">
          <div className="form-signup-container">
            <input type="checkbox" id="rememberMe" className="form-check-input" />
            <label htmlFor="rememberMe" className="form-check-label">
              Remember Me
            </label>
          </div>
          <div>
            <Link to="/forgot-password" className="form-link">
              Forgot Password?
            </Link>
          </div>
        </div>
        <div className="form-control-container">
          <button
            type="submit"
            className="btn btn-primary rounded-pill"
            style={{
              width: "100%",
              height: "60px",
              backgroundColor: "#4545DB",
              color: "#ffffff",
              padding: "10px 10px",
              fontSize: "20px",
              fontWeight: "600",
              border: "none",
              cursor: "pointer",
            }}
            onFocus={(e) => (e.target.style.boxShadow = "none")}
          >
            LOGIN
          </button>
        </div>
      </form>
      <div className="login-footer">
        <div className="signup-link">
          <span>New to Ezhisab?</span>
          <Link to="/create-user" className="form-link-create">
            Create an Account
          </Link>
        </div>
      </div>
    </div>
  </div>
  );
};

export default LoginPage;
