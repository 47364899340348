/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import ReusableModal from "./ReusableModal";
import "./Settings.css";

import AuthContext from "../../../../utils/secure-route/AuthContext";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import { toast, ToastContainer } from "react-toastify";
import SettingTable from "./SettingTable";
import Swal from "sweetalert2";

function ManageVendor() {
  const { user } = useContext(AuthContext);
  const storeId = user.store;
  const [showModal, setShowModal] = useState(false);
  const [vendorName, setVendorName] = useState("");
  const [vendorType, setVendorType] = useState("");
  const [vendorDepartments, setVendorDepartments] = useState([]);
  const [openingBalance, setOpeningBalance] = useState("");
  const [vendors, setVendors] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [editVendorId, setEditVendorId] = useState(null);

  const { Get, Post, Put, Patch } = useApi();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [vendorsResponse, serviceResponse] = await Promise.all([
          Get("vendorDepartmentData"),
          Get("vendorDepartmentServiceData"),
        ]);
        setVendors(vendorsResponse);
        setServiceData(serviceResponse);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    setVendorName("");
    setVendorType("");
    setVendorDepartments([]);
    setOpeningBalance("");
    setEditVendorId(null);
  };

  const handleEdit = (vendor) => {
    setVendorName(vendor.name);
    setVendorType(vendor.type);
    setVendorDepartments(vendor.vendor_department_service || []);
    setOpeningBalance(vendor.opening_balance);
    setEditVendorId(vendor.id);
    setShowModal(true);
  };

  const handleSave = async () => {
    if (vendorName) {
      // Fetch current vendors list using promises
      Get("vendorDepartmentData")
        .then((existingVendors) => {
          // Check for duplicate vendor name
          const isDuplicate = existingVendors.some(
            (vendor) =>
              vendor.name.toLowerCase() === vendorName.toLowerCase() &&
              vendor.id !== editVendorId
          );

          if (isDuplicate) {
            toast.error("Vendor with the same name already exists!");
            return; // Exit early
          }

          const payload = {
            name: vendorName,
            type: vendorType,
            opening_balance: openingBalance,
            vendor_department_service: vendorDepartments,
            store: storeId,
            is_deleted: false,
          };

          if (editVendorId) {
            // Updating existing vendor
            Put("vendorDepartmentData", editVendorId, payload)
              .then((updatedVendor) => {
                setVendors((prev) =>
                  prev.map((vendor) =>
                    vendor.id === editVendorId ? updatedVendor.data : vendor
                  )
                );
                toast.success("Vendor updated successfully!");
                handleClose();
              })
              .catch((error) => {
                console.error("Error updating vendor:", error);
                toast.error("Error updating vendor!");
              });
          } else {
            // Adding new vendor
            Post("vendorDepartmentData", payload)
              .then((response) => {
                setVendors((prev) => [...prev, response.data]);
                toast.success("Vendor added successfully!");
                handleClose();
              })
              .catch((error) => {
                console.error("Error adding vendor:", error);
                toast.error("Error adding vendor!");
              });
          }
        })
        .catch((error) => {
          console.error("Error fetching vendors:", error);
          toast.error("Error fetching vendors.");
        });
    }
  };
  const filteredVendors = vendors.filter((vendor) => !vendor.is_deleted);

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (!result.isConfirmed) return;

      const payload = { is_deleted: true };
      Patch("vendorDepartmentData", id, payload)
        .then(() => {
          setVendors((prev) => prev.filter((vendor) => vendor.id !== id));
          toast.success("Vendor deleted successfully!");
        })
        .catch((error) => {
          console.error("Error deleting vendor:", error);
          toast.error("Error deleting vendor!");
        });
    });
  };

  const handleDepartmentChange = (event) => {
    const value = event.target.value;
    if (value && !vendorDepartments.includes(value)) {
      setVendorDepartments((prev) => [...prev, value]);
    }
  };

  const handleChipRemove = (id) => {
    setVendorDepartments((prev) => prev.filter((deptId) => deptId !== id));
  };

  const departmentOptions = serviceData
    .filter((service) => service.type === "department")
    .map((service) => ({ id: service.id, title: service.title }));

  const expenseTypeOptions = serviceData
    .filter((service) => service.type === "expense type")
    .map((service) => ({ id: service.id, title: service.title }));

  return (
    <>
      <div className="due-days">
        <ToastContainer />

        <div className="header-row">
          <div className="setting-title">Manage Vendor</div>
          <div className="btn-position">
            <button className="vendor-add-button" onClick={handleShow}>
              + Add Vendor
            </button>
          </div>
        </div>
        <SettingTable
          showFooter={true}
          data={filteredVendors}
          columns={[
            { header: "Vendor Name", field: "name" },
            { header: "Vendor Type", field: "type" },
            { header: "Opening Balance", field: "opening_balance" },
            {
              header: "Department / Expense Type",
              field: "vendor_department_service_title",
            },
          ]}
          showAction={true}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
        <ReusableModal
          show={showModal}
          handleClose={handleClose}
          title={editVendorId ? "Edit Vendor" : "Add Vendor"}
          width="auto"
          height="auto"
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSave();
            }}
          >
            <div className="form-group d-flex align-items-center mt-0">
              <input
                type="text"
                id="vendorName"
                className="input-field"
                placeholder="Vendor Name"
                value={vendorName}
                onChange={(e) => setVendorName(e.target.value)}
                style={{ width: "400px", marginRight: "10px", height: "44px" }}
              />
              <select
                id="vendorType"
                className="input-field"
                value={vendorType}
                onChange={(e) => {
                  setVendorType(e.target.value);
                  setVendorDepartments([]); // Reset departments when type changes
                  setOpeningBalance("");
                }}
                style={{ width: "400px", marginRight: "10px", height: "44px" }}
              >
                <option value="">Vendor Type</option>
                <option value="purchase">Purchase</option>
                <option value="expense">Expense</option>
              </select>
            </div>
            {(vendorType === "purchase" || vendorType === "expense") && (
              <div className="form-group">
                <div className="chips-container">
                  {vendorDepartments.map((deptId) => {
                    const department =
                      vendorType === "purchase"
                        ? departmentOptions.find((d) => d.id === deptId)
                        : expenseTypeOptions.find((d) => d.id === deptId);
                    return (
                      <div className="chip" key={deptId}>
                        {department?.title}
                        <span
                          className="chip-close"
                          onClick={() => handleChipRemove(deptId)}
                        >
                          &times;
                        </span>
                      </div>
                    );
                  })}
                </div>
                <select
                  id="vendorDepartment"
                  className="input-field"
                  onChange={handleDepartmentChange}
                  style={{
                    width: "400px",
                    marginRight: "10px",
                    height: "44px",
                  }}
                >
                  <option value="">
                    {vendorType === "purchase"
                      ? "Select Vendor Department"
                      : "Select Expense Type"}
                  </option>
                  {(vendorType === "purchase"
                    ? departmentOptions
                    : expenseTypeOptions
                  ).length === 0 ? (
                    <option value="" disabled>
                      No Vendor / Department found
                    </option> // This is the new option
                  ) : (
                    (vendorType === "purchase"
                      ? departmentOptions
                      : expenseTypeOptions
                    ).map((service) => (
                      <option key={service.id} value={service.id}>
                        {service.title}
                      </option>
                    ))
                  )}
                </select>

                {vendorType === "purchase" && (
                  <input
                    type="number"
                    id="openingBalance"
                    className="input-field"
                    placeholder="Opening Balance"
                    value={openingBalance}
                    onChange={(e) => setOpeningBalance(e.target.value)}
                    style={{
                      width: "400px",
                      marginRight: "10px",
                      height: "44px",
                    }}
                  />
                )}
              </div>
            )}
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <button
                type="submit"
                style={{
                  marginLeft: "690px",
                  padding: "4px 8px",
                  backgroundColor: "#4545db",
                  color: "white",
                  border: "none",
                  borderRadius: "40px",
                  cursor: "pointer",
                  width: "124px",
                  height: "42px",
                  fontSize: "18px",
                }}
              >
                {editVendorId ? "Update" : "Submit"}
              </button>
            </div>
          </form>
        </ReusableModal>
      </div>
    </>
  );
}

export default ManageVendor;
