/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import SettingTable from "../ManageSettings/SettingTable";
import tickets from "../../../../assets/img/tickets.png";
import board from "../../../../assets/img/board.png";
import cylinder from "../../../../assets/img/cylinder.png";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import AuthContext from "../../.././../utils/secure-route/AuthContext";
import { toast,ToastContainer } from "react-toastify";
function GeneralLedgerCashLedger() {
  const [showModal, setShowModal] = useState(false);
  const { user } = useContext(AuthContext);
  const [cashLedger, setCashLedger] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [businessCash, setBusinessCash] = useState();
  const [gasCash, setCashCash] = useState();
  const [cashType, setCashType] = useState("");
  const [lottoCash, setLottoCash] = useState();
  const storeid = user.store;
  const { Post } = useApi();
  const cashTypeMapping = {
    "Business cash": "business_cash",
    "Gas cash": "gas_cash",
    "Lottery cash": "lottery_cash",
  };

  const getCashLedger = async () => {
    if (!startDate || !endDate || !cashType) return;

    const requestData = {
      start_date: startDate,
      end_date: endDate,
      cash_type: cashType,
    };


    try {
      const resp = await Post("cashLedger", requestData);

      // Update cash values
      setBusinessCash(resp.data.current_business_cash);
      setCashCash(resp.data.current_gas_cash);
      setLottoCash(resp.data.current_lottery_cash);
      // Filter data based on selected cash type
      const CashData = resp.data[cashTypeMapping[cashType]] || [];
      if (Array.isArray(CashData)) {
        setCashLedger(CashData);
      } else {
        console.error("Error: Selected cash type data is not an array", CashData);
      }
    } catch (error) {
      console.error("Error getting data", error);
    }
  };

  useEffect(() => {
    getCashLedger();
  }, [startDate, endDate, cashType]); // Monitor state changes

  const handleCashTypeChange = (e) => setCashType(e.target.value);
  const handleStartDateChange = (e) => setStartDate(e.target.value);
  const handleEndDateChange = (e) => setEndDate(e.target.value);


  const toggleModal = () => setShowModal(!showModal);

  const handleCashSubmit = async (e) => {
    e.preventDefault(); // Prevents default page reload
    
    // Create FormData object directly from the event target (form element)
    const formData = new FormData(e.target);
    // Convert FormData to a plain object
    const payload = Object.fromEntries(formData.entries());
    payload.store = storeid;
    payload.type = "cash_ledger";
    try {
      const resp = await Post("bankCashAdjustmentData", payload);
      // Show success message
      toast.success("Cash adjustment data submitted successfully!");
      // Close modal
      setShowModal(false);
    } catch (error) {
      // Handle error
      if (error.response && error.response.data) {
        // Optionally, handle specific error messages here
        toast.error("Error submitting cash adjustment data: " + (error.response.data.message || "Unknown error"));
      } else {
        toast.error("Error submitting cash adjustment data!");
      }
    }
  };
  

  const columns = [
    { header: "Date", field: "date" },
    { header: "Invoice No", field: "invoice_no" },
    { header: "Debit", field: "debit" },
    { header: "Credit", field: "credit" },
    { header: "Current Balance", field: "curent_balance" },
    { header: "Reason", field: "reason" },
  ];

  return (
    <>
    <ToastContainer />
    <div className="main-container mt-2">
      <div className="d-flex p-2">
        <div className="expensecontainer d-flex flex-column pd-2">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3 className="mb-1">Cash Ledger</h3>
            <button className="btn btn-primary cus" onClick={toggleModal}>
              Adjust Balance
            </button>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              marginBottom: "20px",
            }}
          >
            {/* Business Card */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "350px",
                height: "100px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                padding: "16px",
                backgroundColor: "#f0f2ff",
                border: "1px solid #d0d7de",
                fontFamily: "Arial, sans-serif",
              }}
            >
              <div style={{ color: "#0a5f38" }}>
                <h3 style={{ margin: "0", fontSize: "18px" }}>Business</h3>
                <p
                  style={{
                    margin: "8px 0 0",
                    fontSize: "14px",
                    color: "#6b6b6b",
                  }}
                >
                  Current Balance: <strong>$&nbsp;{businessCash}</strong>
                </p>
              </div>
              <div>
                <img
                  src={board}
                  alt="Business Icon"
                  style={{
                    width: "70px",
                    height: "70px",
                    filter: "brightness(1.9)",
                    opacity: 0.4,
                  }}
                />
              </div>
            </div>

            {/* Lottery Card */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "350px",
                height: "100px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                padding: "16px",
                backgroundColor: "#f0f2ff",
                border: "1px solid #d0d7de",
                fontFamily: "Arial, sans-serif",
              }}
            >
              <div style={{ color: "#0a5f38" }}>
                <h3 style={{ margin: "0", fontSize: "18px" }}>Lottery</h3>
                <p
                  style={{
                    margin: "8px 0 0",
                    fontSize: "14px",
                    color: "#6b6b6b",
                  }}
                >
                  Current Balance: <strong>$&nbsp;{gasCash}</strong>
                </p>
              </div>
              <div style={{ opacity: 0.5 }}>
                <img
                  src={tickets}
                  alt="Ticket Icon"
                  style={{
                    width: "80px",
                    height: "80px",
                    filter: "brightness(1.2)",
                    opacity: 0.8,
                  }}
                />
              </div>
            </div>

            {/* Gas Card */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "350px",
                height: "100px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                padding: "16px",
                backgroundColor: "#f0f2ff",
                border: "1px solid #d0d7de",
                fontFamily: "Arial, sans-serif",
              }}
            >
              <div style={{ color: "#0a5f38" }}>
                <h3 style={{ margin: "0", fontSize: "18px" }}>Gas</h3>
                <p
                  style={{
                    margin: "8px 0 0",
                    fontSize: "14px",
                    color: "#6b6b6b",
                  }}
                >
                  Current Balance: <strong>$&nbsp;{lottoCash}</strong>
                </p>
              </div>
              <div style={{ opacity: 0.5 }}>
                <img
                  src={cylinder}
                  alt="Gas Icon"
                  style={{
                    width: "70px",
                    height: "70px",
                    filter: "brightness(1.2)",
                    opacity: 0.5,
                  }}
                />
              </div>
            </div>
          </div>
          {/* <div style={{ display: "flex", gap: "16px", marginBottom: "20px" }}>
            <div className="bank-selector">
              <select
                value={selectedBank}
                onChange={handleBankChange}
                style={{
                  backgroundColor: "#0023000C",
                  borderRadius: "10px",
                  height: "52px",
                }}
              >
                <option value="46bf07aa-aa6f-41f5-a523-1e39a89efcc1">East West Bank</option>
                <option value="rockland-trust-id">Rockland Trust</option>
              </select>
            </div>

            <div className="date-picker">
              <input
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
                style={{
                  backgroundColor: "#0023000C",
                  borderRadius: "10px",
                  height: "52px",
                }}
              />
              <input
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
                style={{
                  backgroundColor: "#0023000C",
                  borderRadius: "10px",
                  height: "52px",
                }}
              />
            </div>
          </div> */}
          <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
            <select
              style={{
                backgroundColor: "#0023000C",
                borderRadius: "10px",
                height: "52px",
              }}
              className="form-control"
              name="cash_type"
              required
              value={cashType}
              onChange={handleCashTypeChange}
            >
              <option value="">Select Cash Type</option>
              <option value="Business cash">Business Cash</option>
              <option value="Gas cash">Gas Cash</option>
              <option value="Lottery cash">Lottery Cash</option>
            </select>

            {/* Start Date Input */}
            <input
              type="date"
              className="form-control"
              value={startDate}
              onChange={handleStartDateChange}
              style={{
                width: "400px",
                backgroundColor: "#0023000C",
                borderRadius: "10px",
                height: "52px",
              }}
            />

            {/* End Date Input */}
            <input
              type="date"
              className="form-control"
              value={endDate}
              onChange={handleEndDateChange}
              style={{
                width: "400px",
                backgroundColor: "#0023000C",
                borderRadius: "10px",
                height: "52px",
              }}
            />
          </div>
          <SettingTable
            data={cashLedger}
            columns={columns}
            showFooter={true}
            showAction={false}
            showExport={true}
            showFilter={false}
          />
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: "10px",
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 1000,
          }}
          onClick={toggleModal}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              width: "700px",
              backgroundColor: "white",
              margin: "100px auto",
              borderRadius: "8px",
              position: "relative",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              padding: "20px",
            }}
          >
            <div
              className="modal-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "none",
              }}
            >
              <h5 className="modal-title" style={{ fontWeight: "bold" }}>
                Adjust Cash
              </h5>
              <svg
                onClick={toggleModal}
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer" }}
              >
                <path
                  d="M1 1L13 13M13 1L1 13"
                  stroke="black"
                  strokeLinecap="round"
                />
              </svg>
            </div>

            <div className="modal-body">
              <form onSubmit={handleCashSubmit}>
                <div className="row">
                  <div className="col-md-4">
                    <input
                      style={{
                        backgroundColor: "#0023000C",
                        borderRadius: "10px",
                        height: "52px",
                      }}
                      type="date"
                      className="form-control"
                      name="date"
                      required
                    />
                  </div>

                  <div className="col-md-4">
                    <select
                      style={{
                        backgroundColor: "#0023000C",
                        borderRadius: "10px",
                        height: "52px",
                      }}
                      type="number"
                      className="form-control"
                      name="cash_type"
                      required
                    >
                      <option value="Business cash">Business Cash</option>
                      <option value="Gas cash">Gas Cash</option>
                      <option value="Lottery cash">Lottery Cash</option>
                    </select>
                  </div>

                  <div className="col-md-4">
                    <input
                      style={{
                        backgroundColor: "#0023000C",
                        borderRadius: "10px",
                        height: "52px",
                      }}
                      type="number"
                      className="form-control"
                      placeholder="$ 100"
                      name="amount"
                      min="0"       // Only allow positive values
                      required
                      onInput={(e) => {
                        // Allow only numbers and a decimal point with two digits after it
                        e.target.value = e.target.value
                          .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                          .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                          .slice(0, 12); // Limit input length (10 digits + 1 decimal + 2 decimal places)
                      }}
                  
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7"></div>
                  <div className="col-md-5">
                    <hr />
                    <button type="submit" className="btn btn-primary" style={{ width: '100%' }}>
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
}
export default GeneralLedgerCashLedger;
