import React, { useState, useEffect } from "react";
import CurrentStore from "./CurrentStore/CurrentStore";
import Dashboard from "../../AppPAges/Dashboard/Dashboard";
import ExpenseBreakdown from "./CurrentStore/ExpenseBreakdown";
import InvoiceTable from "./Invoice/InvoiceTable";
import "../Dashboard/Invoice/Invoice.css";
function MainDashboard() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update window width
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // CSS for different screen sizes using media queries
  const styles = {
    container: {
      flexDirection: "column",
      padding: windowWidth < 768 ? "10px" : "0",
      width: "auto",
      justifyContent: "center", // Center horizontally
      display: "flex",
      alignItems: "center", // Center vertically (if needed)
    },
    contentWrapper: {
      display: "flex",
      flexDirection: windowWidth < 992 ? "column" : "row",
      width: "auto",
    },
    leftColumn: {},
    rightColumn: {
      marginTop: windowWidth < 992 ? "20px" : "0",
    },
    invoiceContainer: {
      marginTop: "10px",
      padding: "10px",
      width: windowWidth < 768 ? "100%" : "100%",
      borderRadius: "10px",
      backgroundColor: "white",
      justifyContent: "center",
      boxSizing: "border-box",
    },
  };

  return (
    <div className="container">
      <div className="" style={styles.container}>
        <div style={styles.contentWrapper}>
          <div style={styles.leftColumn}>
            <Dashboard />
          </div>

          <div style={styles.rightColumn}>
            <CurrentStore />
            <ExpenseBreakdown />
          </div>
        </div>

        <div style={styles.invoiceContainer}>
          <InvoiceTable />
        </div>
      </div>
    </div>
  );
}

export default MainDashboard;
