import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

const ConfirmationModal = ({ open, onConfirm, onCancel, title, description }) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent id="confirmation-dialog-description">
        {description}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} style={{
          color: "#4545db",
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer",
          fontSize: "16px",
        }}>
          No
        </Button>
        <Button onClick={onConfirm} style={{
          color: "white",
          backgroundColor: "#4545db",
          border: "none",
          cursor: "pointer",
          fontSize: "16px",
        }}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;