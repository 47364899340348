/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Report.css";
import { Email, LottoIcon } from "../../../../utils/api-manager/Forms/SvgIcons";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import PrintReport from "../Reports/PrintReport";
import ReusableModal2 from "../ManageSettings/ReusableModal2";
import { toast, ToastContainer } from "react-toastify";
import LotteryReportTable from "../Lottery/LotteryReportTable";
import AuthContext from "../../../../utils/secure-route/AuthContext";
import BusinessReportTab from "./BusinessReportTab";
import LotteryActivationTab from "./LotteryReportTab";
import LotteryManualTicketScan from "./LotteryManualTicketScan";
import { Dialog, DialogContent } from "@mui/material";
import InvoiceForm from "../Dashboard/Invoice/InvoiceForm";

function BusinessReport({ isSelectedDate }) {
  const [hasInitializedLotteryData, setHasInitializedLotteryData] =
    useState(false);
  const initializationInProgress = useRef(false);
  const { Post, Patch, Get } = useApi();
  const navigate = useNavigate();
  const todayDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
  );
  const day = String(todayDate.getDate()).padStart(2, "0");
  const month = String(todayDate.getMonth() + 1).padStart(2, "0");
  const year = todayDate.getFullYear();
  const formattedDate = `${year}-${month}-${day}`;
  const [shifts, setShifts] = useState([]);
  const [selectedShift, setSelectedShift] = useState("");
  const [shiftDetails, setShiftDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    isSelectedDate || formattedDate
  );
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState("");
  const [invoiceFields, setInvoiceFields] = useState([]);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [payrollData, setPayrollData] = useState([]);
  const [selectedPayroll, setSelectedPayroll] = useState(null);
  const [printReport, SetprintReport] = useState();
  const [shift_Id, setShift_Id] = useState();
  const [scanNumber, setScanNumber] = useState("");
  const [reloadOnScan, setReloadOnScan] = useState(false);
  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [username, setusername] = useState([]);
  const [checkedStates, setCheckedStates] = useState({
    meal_tax: "true",
  });
  const [manualValue, setManualValue] = useState("");
  const [isManualEnabled, setIsManualEnabled] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const [mealTaxValue, setMealTaxValue] = useState("");
  const [activeTab, setActiveTab] = useState("activated");
  const [activatedBooks, setActivatedBooks] = useState([]);
  const [returnedBooks, setReturnedBooks] = useState([]);
  const [SetHistory, setSetHistory] = useState([]);
  const [scannedTickets, setScannedTickets] = useState([]);
  const [gameTicketScanNo, setGameTicketScanNo] = useState("");
  const [bookTicketScanNo, setBookTicketScanNo] = useState("");
  const [ticketScanNo, setTicketScanNo] = useState();
  const [lastTicketNo, setLastTicketNo] = useState(0);

  const activatedColumns = [
    { header: "Game Number", field: "game_no" },
    { header: "Book Number", field: "book_no" },
    { header: "Status", field: "status" },
  ];

  const returnedColumns = [
    { header: "Game Number", field: "game_no" },
    { header: "Book Number", field: "book_no" },
    { header: "Ticket Number", field: "total_no_of_ticket_return" },
    { header: "Status", field: "status" },
  ];
  const {
    business_report = {},
    lottery_report = {},
    register_net_sales = [],
  } = shiftDetails;

  const ScanedTicketColumnsAllOver = [
    { header: "Game Price", field: "game_price" },
    { header: "Game Name", field: "game_name" },
    { header: "Game Number", field: "game_no" },
    { header: "Book Number", field: "book_no" },
    { header: "Status", field: "status" },
    { header: "Activated on", field: "active_date" },
    { header: "Over All Tickets", field: "total_ticket_arrived" },
    { header: "Shift Started with Ticket No", field: "last_ticket_no_overall" },
    { header: "Current Ticket No", field: "last_ticket_no_for_shift" },
    { header: "Quantity Sold", field: "sold_tickets" },
    { header: "Total", field: "total_for_today_sold" },
  ];
  const shouldDisplayComponent = (data) => {
    if (Array.isArray(data)) {
      return data.length > 0;
    }
    return data !== null && data !== undefined;
  };
  useEffect(() => {
    if (!isManualEnabled) {
      setManualValue("");
    }
  }, [isManualEnabled, grandTotal]);

  useEffect(() => {
    fetchLotteryInventoryData();
  }, [reloadOnScan, shift_Id]);

  useEffect(() => {
    const updateSettings = (settingsData) => {
      const settings =
        typeof settingsData === "string"
          ? JSON.parse(settingsData)
          : settingsData;

      setCheckedStates((prev) => ({
        ...prev,
        meal_tax: settings.meal_tax ?? false,
      }));

      setIsManualEnabled(settings.instant_sale_manually || false);
    };

    const storedData = localStorage.getItem("settings");
    if (storedData) {
      updateSettings(storedData);
    }

    const handleStorageChange = (e) => {
      if (e.key === "settings" && e.newValue) {
        updateSettings(e.newValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);
  useEffect(() => {
    const fetchPayrollData = () => {
      Get("payrollSalaryData")
        .then((response) => {
          if (response && Array.isArray(response)) {
            // Filter payroll data to only include items where is_in_report is false
            const availablePayrollData = response.filter(
              (payroll) => payroll.is_in_report === false
            );

            setPayrollData(availablePayrollData);
            setSelectedPayroll(null);
          }
        })
        .catch((error) => {
          console.error("Error fetching payroll data:", error);
        });
    };

    fetchPayrollData();
  }, []);

  useEffect(() => {
    const fetchInvoiceData = () => {
      Get("invoiceData")
        .then((response) => {
          if (response && Array.isArray(response)) {
            setInvoices(response);

            const todayDate = getTodayDate();
            // Filter invoices to get only those from today AND where is_in_report is false
            const availableInvoices = response.filter((invoice) => {
              const invoiceDate = new Date(
                new Date(invoice.created_at).toLocaleString("en-US", {
                  timeZone: "America/New_York",
                })
              )
                .toISOString()
                .split("T")[0];
              return (
                invoiceDate === todayDate && invoice.is_in_report === false
              );
            });

            setFilteredInvoices(availableInvoices);
          }
        })
        .catch((error) => {
          console.error("Error fetching invoice data:", error);
        });
    };

    fetchInvoiceData();
  }, []);

  useEffect(() => {
    const fetchShifts = () => {
      Post("getShiftDataWithRole", { date: selectedDate })
        .then((response) => {
          const shiftData = Array.isArray(response?.data?.shift_data)
            ? response.data.shift_data
            : [];

          if (shiftData.length === 0) {
            // No shifts found for the selected date
            toast.info("You have no shifts on this date", {
              position: "top-right",
              autoClose: 3000,
            });

            // Reset to current date
            const currentDate = new Date().toISOString().split("T")[0];
            setSelectedDate(currentDate);
            return; // Exit the function early
          }

          const sortedShifts = shiftData.sort((a, b) => {
            const dateA = a.shift.start_time
              ? new Date(
                  new Date(a.shift.start_time).toLocaleString("en-US", {
                    timeZone: "America/New_York",
                  })
                )
                  .toISOString()
                  .split("T")[0]
              : "";

            const dateB = b.shift.start_time
              ? new Date(
                  new Date(b.shift.start_time).toLocaleString("en-US", {
                    timeZone: "America/New_York",
                  })
                )
                  .toISOString()
                  .split("T")[0]
              : "";

            return dateA.localeCompare(dateB);
          });

          setShifts(sortedShifts);

          if (sortedShifts.length > 0) {
            const userShift = sortedShifts.find(
              (shift) => shift.shift.user === user.user_id
            );
            const selectedShift =
              userShift || sortedShifts[sortedShifts.length - 1];
            setSelectedShift(selectedShift.shift.title);
            setusername(selectedShift.shift_user);
            setShiftDetails(selectedShift);
            setShift_Id(selectedShift.shift.id);
            printDailyReport(selectedShift.shift.id);
          }
        })
        .catch((error) => {
          console.error("Error fetching shift data:", error);

          if (
            error.response?.data?.message ===
            "No shifts found for the provided date"
          ) {
            toast.info("You have no shifts on this date", {
              position: "top-right",
              autoClose: 3000,
            });

            // Reset to current date
            const currentDate = new Date().toISOString().split("T")[0];
            setSelectedDate(currentDate);
          } else {
            toast.error("Error fetching shift data", {
              position: "top-right",
              autoClose: 3000,
            });
          }
        });
    };

    fetchShifts();
  }, [selectedDate, user.user_id]);

  useEffect(() => {
    const fetchData = (shift_new_id) => {
      if (!shift_new_id) {
        console.error("Invalid shift ID");
        return;
      }

      Post("getLotteryInventoryByShift", { shift_id: shift_new_id })
        .then((response) => {
          const filteredResponse = response.data.filter(
            (item) => item.status !== "non activated"
          );
          const activated = response.data.filter(
            (item) => item.status === "activated"
          );
          const returned = response.data.filter(
            (item) => item.status === "returned"
          );
          const scanned = response.data.filter(
            (item) => item.status === "activated"
          );
          setActivatedBooks(activated);
          setReturnedBooks(returned);
          setScannedTickets(scanned);
          setSetHistory(filteredResponse);
          const totalSum = response.data.reduce(
            (sum, item) => sum + (item.total || 0),
            0
          );
          setGrandTotal(totalSum);
          printDailyReport(shift_new_id);
        })
        .catch((error) => {
          console.error("Error fetching lottery inventory data:", error);
        });
    };
    if (shift_Id) {
      fetchData(shift_Id);
    }
  }, [reloadOnScan, shift_Id]);

  useEffect(() => {
    if (lottery_report?.instant_sale_sr34) {
      setManualValue(lottery_report.instant_sale_sr34);
    }
  }, [lottery_report]);

  useEffect(() => {
    setMealTaxValue(shiftDetails.business_report?.meal_tax || "");
  }, [shiftDetails]);
  const handleAddInvoiceClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handlePayrollSelect = async (e) => {
    const payrollId = e.target.value;
    if (payrollId === "") return;
    const selectedPayrollData = payrollData.find(
      (payroll) => payroll.id === payrollId
    );
    setSelectedPayroll(selectedPayrollData);
  };

  function getTicketWithGameAndBook(input) {
    let str = input.toString().slice(0, 14);
    let start = str.slice(0, 3);
    let middle = str.slice(4, -4);
    let end = str.slice(-4).slice(0, 3);
    let obj = {
      gameNo: start,
      bookNo: middle,
      ticketNo: end,
    };
    return obj;
  }
  const handlePayrollAmountChange = (e, payroll) => {
    const newAmount = e.target.value;
    const payload = {
      business_report_id: shiftDetails.business_report?.id,
      type: "other",
      title_id: payroll.payroll_employee_name,
      amount: parseFloat(newAmount),
      object_id: payroll.id,
    };

    Post("purchaseExpenseAndOtherBusinessData", payload)
      .then((resp) => {
        refetchShiftData();
        setSelectedPayroll(null);
      })
      .catch((error) => {
        console.error("Error updating payroll amount:", error);
      });
  };

  const handleEmailButtonClick = () => {
    setShowEmailModal(true);
  };

  const handleSendEmail = () => {
    const payload = {
      shift_id: shiftDetails.shift?.id,
    };

    Post("emailDailyReport", payload)
      .then((response) => {
        setShowEmailModal(false);
        toast.success("Report sent successfully");
      })
      .catch((error) => {
        console.error("Error sending email report:", error);
        toast.error("An error occurred while sending the report");
      });
  };

  const getTodayDate = () => {
    const today = formattedDate;
    return today;
  };

  const handleInvoiceSelect = (e) => {
    const invoiceId = e.target.value;
    if (invoiceId === "addInvoice") {
      handleAddInvoiceClick();
      return;
    }
    const isInvoiceAlreadyAdded = shiftDetails.purchase_expenses?.some(
      (expense) =>
        expense.object_id === invoiceId && expense.type === "purchase_expense"
    );
    if (isInvoiceAlreadyAdded) {
      toast.error("This invoice has already been added to the shift report");
      return;
    }
    const selectedInvoiceData = filteredInvoices.find(
      (inv) => inv.id === invoiceId
    );
    if (!selectedInvoiceData) {
      toast.error("Invalid invoice selected");
      return;
    }
    if (invoiceFields.some((field) => field.invoiceId === invoiceId)) {
      toast.error("This invoice has already been selected");
      return;
    }
    const today = new Date(
      new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    const id = today.getTime(); // This will return the timestamp in Eastern Time

    setSelectedInvoice(invoiceId);
    setInvoiceFields((prev) => [
      ...prev,

      {
        id: id,
        invoiceId,
        invoiceNumber: selectedInvoiceData?.invoice_number ?? "",
        vendorDepartment: selectedInvoiceData?.vendor_department_name ?? "",
        amount: selectedInvoiceData?.total_amount ?? "",
        originalAmount: selectedInvoiceData?.amount ?? "",
      },
    ]);
    e.target.value = "";
  };

  const handleInvoiceFieldChange = (id, fieldName, value) => {
    const updatedFields = invoiceFields.map((field) =>
      field.id === id ? { ...field, [fieldName]: value } : field
    );
    setInvoiceFields(updatedFields);
    const invoiceId = updatedFields.find((field) => field.id === id)?.invoiceId;
    const invoice = filteredInvoices.find(
      (invoice) => invoice.id === invoiceId
    );
    if (invoice) {
      if (fieldName === "amount") {
        const parsedAmount = parseFloat(value);
        if (isNaN(parsedAmount)) {
          toast.error("Invalid amount entered");
          return;
        }
        const payload = {
          business_report_id: shiftDetails.business_report?.id,
          type: "purchase_expense",
          title_id: updatedFields.find((f) => f.id === id).vendorDepartment,
          amount: parsedAmount,
          invoice_no: invoice.invoice_no,
          object_id: invoiceId,
        };
        Post("purchaseExpenseAndOtherBusinessData", payload)
          .then((response) => {
            if (response.status === 200) {
              toast.success("Invoice Amount Updated");
              refetchShiftData();
            }
          })
          .catch((error) => {
            console.error("Error updating invoice amount:", error);
            toast.error(`Update failed: ${error.message || "Unknown error"}`);

            const revertedFields = invoiceFields.map((f) =>
              f.id === id ? { ...f, amount: f.originalAmount } : f
            );
            setInvoiceFields(revertedFields);
          });
      }
    }
  };

  const removeInvoiceField = (id) => {
    setInvoiceFields(invoiceFields.filter((field) => field.id !== id));
  };

  const calculateTotalInvoiceAmount = () => {
    return invoiceFields.reduce((total, field) => {
      const amount = parseFloat(field.originalAmount) || 0;
      return total + amount;
    }, 0);
  };

  const handleRegisterField = async (registerNo, field, value) => {
    const finalValue = value === "" || value === undefined ? 0 : value;
    const payload = {
      business_report: shiftDetails.business_report?.id,
      register_no: registerNo,
      [field]: finalValue,
    };
    await Post("updateRegisterAndBusinessReport", payload)
      .then((response) => {
        setShiftDetails((prevDetails) => ({
          ...prevDetails,
          register_net_sales: prevDetails.register_net_sales.map((register) => {
            if (register.register_no === registerNo) {
              return {
                ...register,
                [field]: finalValue,
              };
            }
            return register;
          }),
        }));
        refetchShiftData();
      })
      .catch((error) => {
        console.error(
          `Error updating ${field} for register ${registerNo}:`,
          error
        );
      });
  };

  const handleGlobalField = async (field, value) => {
    const businessReportId = shiftDetails.business_report?.id;
    if (!businessReportId) {
      console.error("Business report ID is missing.");
      return;
    }
    const payload = {
      business_report: businessReportId,
      [field]: value,
    };
    Post("updateRegisterAndBusinessReport", payload)
      .then((response) => {
        setShiftDetails((prevDetails) => ({
          ...prevDetails,
          business_report: {
            ...prevDetails.business_report,
            [field]: value,
          },
        }));
        refetchShiftData();
      })
      .catch((error) => {
        console.error("Error Updating Field", error);
      });
  };

  const handleFieldChange = (
    registerNo,
    field,
    value,
    isRegisterField = true
  ) => {
    if (isRegisterField) {
      handleRegisterField(registerNo, field, value);
    } else {
      handleGlobalField(field, value);
    }
  };

  const handleLotteryRegisterField = async (registerNo, field, value) => {
    const payload = {
      lottery_report: shiftDetails.lottery_report?.id,
      register_no: registerNo,
      [field]: value,
    };

    await Post("updateLotteryReport", payload)
      .then((response) => {
        setShiftDetails((prevDetails) => ({
          ...prevDetails,
          lottery_register_reports: prevDetails.lottery_register_reports.map(
            (register) => {
              if (register.register_no === registerNo) {
                return {
                  ...register,
                  [field]: value,
                };
              }
              return register;
            }
          ),
        }));
        refetchShiftData();
      })
      .catch((error) => {
        console.error(
          `Error updating ${field} for register ${registerNo}:`,
          error
        );
      });
  };

  const handleLotteryGlobalField = async (field, value) => {
    const payload = {
      lottery_report: shiftDetails.lottery_report?.id,
      [field]: value,
    };

    Post("updateLotteryReport", payload)
      .then((response) => {
        setShiftDetails((prevDetails) => ({
          ...prevDetails,
          lottery_report: {
            ...prevDetails.lottery_report,
            [field]: value,
          },
        }));
        refetchShiftData();
      })
      .catch((error) => {
        console.error(`Error updating ${field}:`, error);
      });
  };

  const handleLotteryFieldChange = (
    registerNo,
    field,
    value,
    isRegisterField = true
  ) => {
    if (isRegisterField) {
      handleLotteryRegisterField(registerNo, field, value);
    } else {
      handleLotteryGlobalField(field, value);
    }
  };

  const handleShiftChange = (e) => {
    const selectedTitle = e.target.value;
    setSelectedShift(selectedTitle);

    const selectedShiftDetails = shifts.find(
      (shift) => shift.shift.title === selectedTitle
    );

    if (selectedShiftDetails) {
      setShiftDetails(selectedShiftDetails);
      printDailyReport(selectedShiftDetails.shift.id);
    } else {
      console.warn("No shift details found for selected title");
      setShiftDetails({});
    }
  };

  const handleDateChange = (e) => {
    const newDate = e.target.value;

    // Check if the selected date has shifts before updating
    Post("getShiftDataWithRole", { date: newDate })
      .then((response) => {
        const shiftData = Array.isArray(response?.data?.shift_data)
          ? response.data.shift_data
          : [];

        if (shiftData.length === 0) {
          toast.info("You have no shifts on this date", {
            position: "top-right",
            autoClose: 3000,
          });

          // Reset to current date if no shifts exist
          const currentDate = new Date().toISOString().split("T")[0];
          setSelectedDate(currentDate);
        } else {
          // Update the date only if shifts exist
          setSelectedDate(newDate);
          setHasInitializedLotteryData(false);
        }
      })
      .catch((error) => {
        console.error("Error checking shifts for date:", error);

        if (
          error.response?.data?.message ===
          "No shifts found for the provided date"
        ) {
          toast.info("You have no shifts on this date", {
            position: "top-right",
            autoClose: 3000,
          });

          // Reset to current date
          const currentDate = new Date().toISOString().split("T")[0];
          setSelectedDate(currentDate);
        } else {
          toast.error("Error checking shifts for date", {
            position: "top-right",
            autoClose: 3000,
          });
        }
      });
  };
  const handleGameReturnNoChange = (e) => {
    const value = e.target.value;

    if (/^\d{0,20}$/.test(value)) {
      setGameReturnNo(value);
    } else {
      console.log("Only 20 numeric characters are allowed.");
    }
  };
  const handleBookNoReturnChange = (e) => {
    const value = e.target.value;

    if (/^\d{0,20}$/.test(value)) {
      setBookReturnNo(value);
    } else {
      console.log("Only 20 numeric characters are allowed.");
    }
  };
  const handleTicketNoChange = (e) => {
    const value = e.target.value;

    if (/^\d{0,20}$/.test(value)) {
      setTicketNo(value);
    } else {
      console.log("Only 20 numeric characters are allowed.");
    }
  };
  const handleModalShift = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleShiftEnd = () => {
    const today = formattedDate;
    localStorage.clear("shiftDetails");
    const currentUserShift = shifts.find(
      (shift) => shift.shift.user === user.user_id && shift.shift.is_active
    );
    if (!currentUserShift) {
      toast.error("No active shift found for the current user");
      setShowModal(false);
      return;
    }
    if (selectedDate !== today && currentUserShift) {
      toast.warning("Please select today's date to end your current shift");
      setSelectedDate(today);
      Post("getShiftDataWithRole", { date: today })
        .then((response) => {
          const shiftData = Array.isArray(response?.data?.shift_data)
            ? response.data.shift_data
            : [];
          const sortedShifts = shiftData.sort((a, b) => {
            const dateA = a.shift.start_time
              ? new Date(
                  new Date(a.shift.start_time).toLocaleString("en-US", {
                    timeZone: "America/New_York",
                  })
                )
                  .toISOString()
                  .split("T")[0] // Extract only the date part
              : "";

            const dateB = b.shift.start_time
              ? new Date(
                  new Date(b.shift.start_time).toLocaleString("en-US", {
                    timeZone: "America/New_York",
                  })
                )
                  .toISOString()
                  .split("T")[0] // Extract only the date part
              : "";

            return dateA.localeCompare(dateB); // Compare date strings lexicographically
          });

          setShifts(sortedShifts);
          const userActiveShift = sortedShifts.find(
            (shift) =>
              shift.shift.user === user.user_id && shift.shift.is_active
          );
          if (userActiveShift) {
            setSelectedShift(userActiveShift.shift.title);
            setShiftDetails(userActiveShift);
            setShift_Id(userActiveShift.shift.id);
            printDailyReport(userActiveShift.shift.id);
          }
        })
        .catch((error) => {
          console.error("Error fetching current shift data:", error);
          toast.error("Error loading current shift data");
        });
      return;
    }
    Patch(`shiftData`, currentUserShift.shift.id, { is_active: false })
      .then((response) => {
        if (response.status === 200) {
          const settings = JSON.parse(localStorage.getItem("settings")) || {};
          const autoEmailReport = settings.auto_email_report ?? false;
          if (autoEmailReport) {
            handleSendEmail();
          }
          navigate("/reportDateSelection");
          toast.success("Shift ended successfully");
        }
      })
      .catch((error) => {
        console.error("Error ending shift:", error);
        toast.error("Failed to end shift");
      })
      .finally(() => {
        setShowModal(false);
      });
  };

  const refetchShiftData = async () => {
    try {
      const response = await Post("getShiftDataWithRole", {
        date: selectedDate,
      });

      const shiftData = Array.isArray(response?.data?.shift_data)
        ? response.data.shift_data
        : [];

      const sortedShifts = shiftData.sort((a, b) => {
        const timeA = a.shift.start_time
          ? new Date(
              new Date(a.shift.start_time).toLocaleString("en-US", {
                timeZone: "America/New_York",
              })
            ).getTime()
          : 0;
        const timeB = b.shift.start_time
          ? new Date(
              new Date(b.shift.start_time).toLocaleString("en-US", {
                timeZone: "America/New_York",
              })
            ).getTime()
          : 0;

        return timeA - timeB;
      });

      setShifts(sortedShifts);
      const currentlySelectedShiftDetails = sortedShifts.find(
        (shift) => shift.shift.title === selectedShift
      );

      if (currentlySelectedShiftDetails) {
        setShiftDetails(currentlySelectedShiftDetails);
        setShift_Id(currentlySelectedShiftDetails.shift.id);
        printDailyReport(currentlySelectedShiftDetails.shift.id);
      } else if (sortedShifts.length > 0) {
        const userShift = sortedShifts.find(
          (shift) => shift.shift.user === user.user_id
        );
        const fallbackShift =
          userShift || sortedShifts[sortedShifts.length - 1];

        setSelectedShift(fallbackShift.shift.title);
        setShiftDetails(fallbackShift);
        setShift_Id(fallbackShift.shift.id);
        printDailyReport(fallbackShift.shift.id);
      }

      const todayInvoices = invoices.filter((invoice) => {
        const invoiceDate = new Date(
          new Date(invoice.created_at).toLocaleString("en-US", {
            timeZone: "America/New_York",
          })
        )
          .toISOString()
          .split("T")[0];

        return invoiceDate === selectedDate;
      });
      setFilteredInvoices(todayInvoices);
      setInvoiceFields([]);
      setSelectedInvoice("");
    } catch (error) {
      console.error("Error fetching updated shift data:", error);
    }
  };

  const printDailyReport = async (shiftId) => {
    let siftid = shiftId || shift_Id;
    await Post(`printDailyReport`, { shift_id: siftid })
      .then((response) => {
        SetprintReport(response.data);
        const data = response.data;
        localStorage.setItem("printDailyReport", JSON.stringify(data));
      })
      .catch((error) => {
        console.log("error");
      });
  };

  const {
    total_net_sales: totalNetSales = "N/A",
    total_net_taxes: totalNetTaxes = "N/A",
    gross_sales: grossSales = "N/A",
    refund = "N/A",
    cheque = "N/A",
    total_register_cash: totalRegisterCash = "N/A",
    cash_to_account: cashToAccount = "N/A",
    over_short: overShort = "N/A",
    credit_card = "N/A",
    meal_tax = "N/A",
  } = business_report || {};

  const [gameNo, setGameNo] = useState("");
  const [bookNo, setBookNo] = useState("");

  const [gameReturnNo, setGameReturnNo] = useState("");
  const [bookReturnNo, setBookReturnNo] = useState("");
  const [ticketNo, setTicketNo] = useState("");

  const [reloadLottoTable, setReloadLottoTable] = useState(false);
  // Update the handleActivate function to increment reloadLottoTable
  const handleActivate = () => {
    const payload = {
      game_no: gameNo,
      book_no: bookNo,
      active_date: formattedDate,
      status: "activated",
      activated_book_shift: shift_Id,
    };

    Post("updateGameAndInventory", payload)
      .then(() => {
        toast.success("Game activated successfully!");
        setGameNo("");
        setBookNo("");
        setScanNumber("");
        // Use increment instead of toggle to ensure state always changes
        setReloadLottoTable((prev) => prev + 1);
        // Also update reloadOnScan for consistency
        setReloadOnScan((prev) => !prev);

        // Refetch lottery inventory data
        fetchLotteryInventoryData();
      })
      .catch((error) => {
        // Show error message directly from the response.error
        const errorMessage =
          error?.response?.data?.error ||
          "There was an error returning the book.";
        const capitalizedErrorMessage =
          errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
        toast.error(capitalizedErrorMessage);

        setScanNumber("");
      });
  };

  const fetchLotteryInventoryData = () => {
    if (!shift_Id) {
      console.error("Invalid shift ID");
      return;
    }

    Post("getLotteryInventoryByShift", { shift_id: shift_Id })
      .then((response) => {
        const filteredResponse = response.data.filter(
          (item) => item.status !== "non activated"
        );
        const activated = response.data.filter(
          (item) => item.status === "activated"
        );
        const returned = response.data.filter(
          (item) => item.status === "returned"
        );
        const scanned = response.data.filter(
          (item) => item.status === "activated"
        );
        setActivatedBooks(activated);
        setReturnedBooks(returned);
        setScannedTickets(scanned);
        setSetHistory(filteredResponse);
        const totalSum = response.data.reduce(
          (sum, item) => sum + (item.total || 0),
          0
        );
        setGrandTotal(totalSum);
        printDailyReport(shift_Id);
      })
      .catch((error) => {
        console.error("Error fetching lottery inventory data:", error);
      });
  };

  const handleDeleteInvoice = async (invoiceId) => {
    try {
      const invoiceToDelete = shiftDetails.purchase_expenses.find(
        (expense) => expense.id === invoiceId
      );

      if (!invoiceToDelete) {
        toast.error("Invoice not found");
        return;
      }

      const payload = {
        business_report_id: shiftDetails.business_report?.id,
        object_id: invoiceToDelete.object_id,
        delete: "true",
      };

      await Post("purchaseExpenseAndOtherBusinessData", payload)
        .then((response) => {
          toast.success("Invoice deleted successfully!");

          const updatedPurchaseExpenses = shiftDetails.purchase_expenses.filter(
            (expense) => expense.id !== invoiceId
          );

          setShiftDetails((prevDetails) => ({
            ...prevDetails,
            purchase_expenses: updatedPurchaseExpenses,
            business_report: {
              ...prevDetails.business_report,
              total_purchase_expense: (
                parseFloat(prevDetails.business_report.total_purchase_expense) -
                parseFloat(invoiceToDelete.amount)
              ).toFixed(2),
            },
          }));
        })
        .catch((errpr) => {
          toast.error("Failed to delete invoice");
        });
    } catch (error) {
      console.error("Error deleting invoice:", error);
      toast.error("An error occurred while deleting the invoice");
    }
  };

  const handleDeletePayrollEntry = async (payrollId) => {
    try {
      const payload = {
        business_report_id: shiftDetails.business_report?.id,
        object_id: payrollId.object_id,
        delete: "true",
      };

      const response = await Post(
        "purchaseExpenseAndOtherBusinessData",
        payload
      );

      if (response && response.status === 200) {
        toast.success("Payroll entry deleted successfully!");

        setPayrollData((prevPayrollData) =>
          prevPayrollData.filter((entry) => entry.id !== payrollId)
        );

        refetchShiftData();
      } else {
        toast.error("Failed to delete payroll entry");
      }
    } catch (error) {
      console.error("Error deleting payroll entry:", error);
      toast.error("An error occurred while deleting the payroll entry");
    }
  };

  const handleReturn = () => {
    const today = formattedDate;

    const payload = {
      returned_book_shift: shift_Id,
      game_no: gameReturnNo,
      book_no: bookReturnNo,
      total_no_of_ticket_return: ticketNo,
      return_date: today,
      status: "returned",
    };

    Post("updateGameAndInventory", payload)
      .then((response) => {
        if (response && response.data) {
          const returnedInventory = response.data.lotteryInventory;

          if (returnedInventory) {
            if (returnedInventory.returned_book_shift) {
              toast.success("Book returned successfully!");
              setGameReturnNo("");
              setBookReturnNo("");
              setTicketNo("");
              setScanNumber("");
              // Use increment instead of toggle to ensure state always changes
              setReloadLottoTable((prev) => prev + 1);
              // Also update reloadOnScan for consistency
              setReloadOnScan((prev) => !prev);

              // Refetch lottery inventory data
              fetchLotteryInventoryData();
            } else {
              console.error(
                "Returned book shift was not updated properly on the backend."
              );
              toast.error("Returned book shift not updated properly.");
            }
          } else {
            console.error("Lottery inventory is missing from the response.");
            toast.error("Failed to return book. Lottery inventory missing.");
          }
        } else {
          console.error("Response or data is missing.");
          toast.error("Failed to return book. Response missing or incorrect.");
        }
      })
      .catch((error) => {
        // Get the error message, capitalize the first letter of the first word
        const errorMessage =
          error?.response?.data?.error ||
          "There was an error returning the book.";
        const capitalizedErrorMessage =
          errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);

        // Show the capitalized error message
        toast.error(capitalizedErrorMessage);
      });
  };

  const handleTabClick = (tab) => {
    setReloadLottoTable(reloadLottoTable);
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
  };

  const handleGameNoChange = (e) => {
    const value = e.target.value;

    if (/^\d{0,20}$/.test(value)) {
      setGameNo(value);
    } else {
      console.log("Only 20 numeric characters are allowed.");
    }
  };
  const handleBookNoChange = (e) => {
    const value = e.target.value;

    if (/^\d{0,20}$/.test(value)) {
      setBookNo(value);
    } else {
      console.log("Only 20 numeric characters are allowed.");
    }
  };
  const handleGameTicketScanChange = (e) => {
    const value = e.target.value;

    if (/^\d{0,20}$/.test(value)) {
      setGameTicketScanNo(value);
    } else {
      console.log("Only 20 numeric characters are allowed.");
    }
  };
  const handleBookTicketScanChange = (e) => {
    const value = e.target.value;

    if (/^\d{0,20}$/.test(value)) {
      setBookTicketScanNo(value);
    } else {
      console.log("Only 20 numeric characters are allowed.");
    }
  };
  const handleTicketScanChange = (e) => {
    const value = e.target.value;

    if (/^\d{0,20}$/.test(value)) {
      setTicketScanNo(value);
    } else {
      console.log("Only 20 numeric characters are allowed.");
    }
  };

  const handleLotteryTicketScan = () => {
    const payload = {
      activated_book_shift: shift_Id,
      game_no: gameTicketScanNo,
      book_no: bookTicketScanNo,
      today_ticket_no: ticketScanNo,
      shift_id: shift_Id,
    };

    Post("updateGameAndInventory", payload)
      .then((response) => {
        toast.success("Ticket scanned successfully!");

        setGameTicketScanNo("");
        setBookTicketScanNo("");
        setTicketScanNo("");
        setScanNumber("");
        setTicketNo("");
        setTicketScanNo("");

        setReloadLottoTable((prev) => prev + 1); // Update with increment
        setReloadOnScan((prev) => !prev);
        fetchLotteryInventoryData();
      })
      .catch((error) => {
        // Capitalize the error message
        const errorMessage =
          error?.response?.data?.error ||
          "There was an error scanning the ticket.";
        const capitalizedErrorMessage =
          errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);

        // Reset reload table in case of error
        setReloadLottoTable(reloadLottoTable);

        console.error("Failed to scan ticket:", error);
        toast.error(capitalizedErrorMessage);
      });
  };

  const handleScanNumberChange = (e) => {
    let newScanNumber = e.target.value;

    if (/^\d{0,50}$/.test(newScanNumber)) {
      setScanNumber(newScanNumber);

      if (newScanNumber.length >= 14) {
        const _gameNo = getTicketWithGameAndBook(newScanNumber).gameNo;
        const _bookNo = getTicketWithGameAndBook(newScanNumber).bookNo;
        const _ticketNo = getTicketWithGameAndBook(newScanNumber).ticketNo;
        let todayTicketNo = getTodayTicketNo(scannedTickets, _gameNo);

        setLastTicketNo(todayTicketNo);

        if (newScanNumber.length === 15) {
          setReloadOnScan(!reloadOnScan);
        }

        setGameNo(_gameNo);
        setBookNo(_bookNo);
        setTicketNo(_ticketNo);
        setTicketScanNo(_ticketNo);
        setGameReturnNo(_gameNo);
        setBookReturnNo(_bookNo);
        setGameTicketScanNo(_gameNo);
        setBookTicketScanNo(_bookNo);
      }
    } else {
      console.log("Only numeric characters up to 50 digits are allowed.");
    }
  };
  const InvoiceAmountField = ({ field, onAmountSubmit }) => {
    if (!field) return null;

    const handleAutoFill = () => {
      if (field && field.id && field.originalAmount) {
        onAmountSubmit(field.id, "amount", field.originalAmount);
      }
    };

    return (
      <div className="">
        <div className="">
          <button
            className="btn business-button"
            onClick={handleAutoFill}
            style={{
              backgroundColor: "#4545DB",
              color: "white",
            }}
          >
            Add
          </button>
        </div>
      </div>
    );
  };

  const getTodayTicketNo = (dataArray, gameNumber) => {
    const game = dataArray.find((item) => item.game_no === gameNumber);
    return game ? game.today_ticket_no : null;
  };
  const renderRegisterInputs = (registers, fieldName, labelPrefix) => {
    if (!registers || !Array.isArray(registers) || registers.length === 0) {
      console.warn(`No valid registers found for ${fieldName}`);
      return null;
    }

    return registers
      .sort((a, b) => a.register_no - b.register_no)
      .map((register, index) => {
        const registerValue =
          register[fieldName] !== undefined && register[fieldName] !== null
            ? register[fieldName].toString()
            : "";

        return (
          <div
            key={`${fieldName}_${register.register_no}`}
            className="data-report"
          >
            <label
              htmlFor={`${fieldName}_${register.register_no}`}
              className="label"
            >
              {`${labelPrefix} ${index + 1}`}
            </label>
            <input
              type="text"
              step="any"
              id={`${fieldName}_${register.register_no}`}
              name={`${fieldName}_${register.register_no}`}
              className="input"
              max="9999"
              value={registerValue}
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .slice(0, 12);
              }}
              onChange={(e) => {
                let newValue = e.target.value;

                if (newValue === "" || newValue === undefined) {
                  newValue = "0";
                }

                const updatedRegisters = [...registers];
                const registerIndex = updatedRegisters.findIndex(
                  (r) => r.register_no === register.register_no
                );
                if (registerIndex !== -1) {
                  updatedRegisters[registerIndex] = {
                    ...updatedRegisters[registerIndex],
                    [fieldName]: newValue,
                  };

                  setShiftDetails((prevDetails) => ({
                    ...prevDetails,
                    register_net_sales: updatedRegisters,
                  }));
                }
              }}
              onBlur={(e) => {
                let finalValue = e.target.value;

                if (finalValue === "" || finalValue === undefined) {
                  finalValue = "0";
                }

                handleFieldChange(register.register_no, fieldName, finalValue);
              }}
            />
          </div>
        );
      });
  };

  useEffect(() => {
    if (
      !hasInitializedLotteryData &&
      !initializationInProgress.current &&
      shiftDetails &&
      shiftDetails.yesterday_lottery_report &&
      shiftDetails.yesterday_lottery_report.length > 0 &&
      shiftDetails.lottery_register_reports &&
      shiftDetails.lottery_register_reports.length > 0
    ) {
      initializationInProgress.current = true;

      // Prepare all data at once
      const yesterdayReport = shiftDetails.yesterday_lottery_report[0];
      const fieldMap = {
        net_sales_sr51: "net_sales_sr50",
        online_cashing_sr51: "online_cashing_sr50",
        instant_cashing_sr35: "instant_cashing_sr34",
      };

      // Prepare updates but don't send API calls yet
      const updates = [];

      shiftDetails.lottery_register_reports.forEach((register) => {
        const updateData = {
          lottery_report: shiftDetails.lottery_report?.id,
          register_no: register.register_no,
        };

        Object.keys(fieldMap).forEach((fieldName) => {
          let valueToSet = "0.00";

          if (
            shiftDetails.yesterday_lottery_report &&
            shiftDetails.yesterday_lottery_report.length > 0
          ) {
            const yesterdayRegister =
              yesterdayReport.yesterday_lottery_register_reports.find(
                (reg) => reg.register_no === register.register_no
              );

            if (yesterdayRegister) {
              valueToSet = yesterdayRegister[fieldMap[fieldName]] || "0.00";
            }
          }

          updateData[fieldName] = valueToSet;
        });

        updates.push(updateData);
      });

      // Process all updates with Promise.all
      Promise.all(updates.map((update) => Post("updateLotteryReport", update)))
        .then(() => {
          return refetchShiftData();
        })
        .then(() => {
          setHasInitializedLotteryData(true);
          initializationInProgress.current = false;
        })
        .catch((error) => {
          console.error("Error updating lottery report:", error);
          initializationInProgress.current = false;
        });
    }
  }, [shiftDetails.yesterday_lottery_report, hasInitializedLotteryData]);

  const renderLotteryRegisterInputs = (registers, fieldName, labelPrefix) => {
    if (!registers || registers.length === 0) {
      console.warn(`No lottery registers found for ${fieldName}`);
      return null;
    }

    // Mapping between today's fields and yesterday's fields
    const fieldMap = {
      net_sales_sr51: "net_sales_sr50",
      online_cashing_sr51: "online_cashing_sr50",
      instant_cashing_sr35: "instant_cashing_sr34",
    };

    return registers
      .sort((a, b) => a.register_no - b.register_no)
      .map((register, index) => {
        let savedValue = register[fieldName] || "";

        // If this is a field that should show yesterday's data and we have yesterday's data
        if (
          fieldMap[fieldName] &&
          shiftDetails.yesterday_lottery_report &&
          shiftDetails.yesterday_lottery_report.length > 0
        ) {
          // Find the corresponding register from yesterday's data
          const yesterdayRegister =
            shiftDetails.yesterday_lottery_report[0].yesterday_lottery_register_reports.find(
              (reg) => reg.register_no === register.register_no
            );

          // Use yesterday's value for the mapped field if available
          if (yesterdayRegister) {
            savedValue = yesterdayRegister[fieldMap[fieldName]] || "0.00";
          }
        }

        return (
          <div
            key={`${fieldName}_${register.register_no}`}
            className="data-report"
          >
            <label
              htmlFor={`${fieldName}_${register.register_no}`}
              className="label"
            >
              {`${labelPrefix} ${index + 1}`}
            </label>
            <input
              type="text"
              step="any"
              id={`${fieldName}_${register.register_no}`}
              name={`${fieldName}_${register.register_no}`}
              className="input"
              value={savedValue}
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .slice(0, 12);
              }}
              onChange={(e) => {
                let newValue = e.target.value;

                if (newValue === "" || newValue === undefined) {
                  newValue = "0";
                }

                const updatedRegisters = registers.map((reg) =>
                  reg.register_no === register.register_no
                    ? { ...reg, [fieldName]: newValue }
                    : reg
                );

                setShiftDetails((prevDetails) => ({
                  ...prevDetails,
                  lottery_register_reports: updatedRegisters,
                }));
              }}
              onBlur={(e) => {
                let finalValue = e.target.value;

                if (finalValue === "" || finalValue === undefined) {
                  finalValue = "0";
                }

                const updatedRegisters = registers.map((reg) =>
                  reg.register_no === register.register_no
                    ? { ...reg, [fieldName]: finalValue }
                    : reg
                );

                setShiftDetails((prevDetails) => ({
                  ...prevDetails,
                  lottery_register_reports: updatedRegisters,
                }));

                handleLotteryFieldChange(
                  register.register_no,
                  fieldName,
                  finalValue,
                  true
                );
              }}
            />
          </div>
        );
      });
  };

  const filteredExpenses = Array.isArray(shiftDetails.purchase_expenses)
    ? shiftDetails.purchase_expenses.filter((entry) => entry.type === "other")
    : [];
  const availableInvoices = filteredInvoices.filter(
    (invoice) =>
      !shiftDetails.purchase_expenses?.some(
        (expense) =>
          expense.object_id === invoice.id &&
          expense.type === "purchase_expense"
      ) &&
      !invoiceFields.some((field) => field.invoiceId === invoice.id) &&
      invoice.is_in_report === false // Ensures the invoice is not in the report
  );

  const availablePayrollEntries = payrollData.filter(
    (payroll) =>
      !shiftDetails.purchase_expenses?.some(
        (expense) =>
          expense.object_id === payroll.id && expense.type === "other"
      ) && payroll.is_in_report === false // Ensure the payroll entry is not in the report
  );
  const navigateToActiveShift = () => {
    navigate("/reportDateSelection", {
      state: { selectedDate: formattedDate },
    });
  };
  return (
    <div className="business-header">
      <div className="header-row">
        <div className="business-title">
          <div className="dropdown">
            <select
              id="shift-select"
              value={selectedShift}
              className="dropdown-toggle shift"
              onChange={handleShiftChange}
              style={{
                padding: "10px",
                marginBottom: "20px",
                fontSize: "16px",
                height: "44px",
              }}
            >
              {shifts.length > 0 ? (
                shifts.map((shiftItem) => (
                  <option
                    key={shiftItem.shift.id}
                    value={shiftItem.shift.title}
                    className="shift-text"
                  >
                    {shiftItem.shift.title}
                  </option>
                ))
              ) : (
                <option value="">No active shifts available</option>
              )}
            </select>
          </div>
          <input
            className="form-control"
            type="date"
            name="reportDate"
            value={selectedDate}
            onChange={handleDateChange}
            style={{
              backgroundColor: "#f4f4f4",
              marginLeft: "10px",
              height: "44px",
            }}
          />
        </div>
        <div className="btn-position">
          <PrintReport
            reportData={printReport}
            selectedShift={selectedShift}
            today={formattedDate}
          />

          <button
            className="business-button button-height"
            onClick={handleSendEmail}
          >
            <Email />
            Email Report
          </button>

          <button
            className="business-button del button-height"
            onClick={
              selectedDate !== formattedDate
                ? navigateToActiveShift
                : handleModalShift
            }
          >
            {selectedDate !== formattedDate ? "Back to Shift" : "End Shift"}
          </button>
          <ReusableModal2
            show={showModal}
            handleClose={handleCloseModal}
            title="End Shift Confirmation"
            title2="Do you really want to end this shift?"
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleShiftEnd();
              }}
            >
              <div className="col-md-3 d-flex align-items-center">
                <label htmlFor="shift" className="form-label mb-0 me-2">
                  Shift:
                </label>
                <input
                  style={{ width: "220px" }}
                  type="text"
                  id="shift"
                  className="form-control"
                  value={selectedShift}
                  readOnly
                />
                &nbsp;&nbsp;&nbsp; &nbsp;
                <input
                  style={{ width: "220px" }}
                  type="text"
                  id="shift"
                  className="form-control"
                  value={username}
                  readOnly
                />
              </div>
              <div className="col-md-3 d-flex align-items-center">
                <label htmlFor="time" className="form-label mb-0 me-2">
                  Time:
                </label>
                <input
                  style={{ width: "220px" }}
                  type="text"
                  id="time"
                  className="form-control"
                  value={new Date().toLocaleString("en-US", {
                    timeZone: "America/New_York",
                  })}
                  readOnly
                />
              </div>
              &nbsp;
              <div className="d-flex justify-content-end mb-3">
                <button
                  type="button"
                  className="btn btn-contained me-2"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn"
                  style={{
                    backgroundColor: "#E7785F",
                    width: "150px",
                    borderRadius: "50px",
                    color: "white",
                  }}
                >
                  End Shift
                </button>
              </div>
            </form>
          </ReusableModal2>
        </div>
      </div>

      {shouldDisplayComponent(shiftDetails.business_report) && (
        <>
          <div className="row business-title-header">
            <div className="col-md-6">
              <p className="business-report-title">Business Report</p>
            </div>
            <div className="col-md-6">
              <p className="business-report-title desktop-title">
                Purchase/Expense
              </p>
            </div>
          </div>
          <div className="row business-report">
            <div className="col-md-6">
              <BusinessReportTab
                registerNetSales={register_net_sales}
                savedShiftDetails={shiftDetails}
                totalNetSales={totalNetSales}
                totalNetTaxes={totalNetTaxes}
                checkedStates={checkedStates}
                mealTaxValue={mealTaxValue}
                grossSales={grossSales}
                refund={refund}
                cheque={cheque}
                creditCard={credit_card}
                totalRegisterCash={totalRegisterCash}
                cashToAccount={cashToAccount}
                overShort={overShort}
                setShiftDetails={setShiftDetails}
                handleGlobalField={handleGlobalField}
                setMealTaxValue={setMealTaxValue}
                renderRegisterInputs={renderRegisterInputs}
              />
            </div>

            {shouldDisplayComponent(shiftDetails.business_report) && (
              <div className="col-md-6">
                <div className="col-md-12">
                  <div className="col-md-12">
                    <div className="col-md-12">
                      <p className="invoice-title mobile-title">
                        Purchase/Expense
                      </p>
                      <div className="data-report">
                        <label htmlFor="invoice" className="label">
                          Select Invoice
                        </label>
                        <select
                          className="dropdown-toggle shift"
                          style={{
                            padding: "10px",
                            marginBottom: "20px",
                            fontSize: "16px",
                            height: "44px",
                          }}
                          value={selectedInvoice}
                          onChange={handleInvoiceSelect}
                        >
                          <option value="">Select Invoice</option>
                          <option value="addInvoice">+ Add Invoice</option>
                          {availableInvoices.map((invoice) => (
                            <option key={invoice.id} value={invoice.id}>
                              {invoice.invoice_number} -{" "}
                              {invoice.vendor_department_name} -{" "}
                              {invoice.invoice_no}
                            </option>
                          ))}
                        </select>

                        <Dialog open={open} maxWidth="lg" fullWidth>
                          <DialogContent
                            style={{ backgroundColor: "transparent" }}
                          >
                            <InvoiceForm onCancel={handleClose} />
                          </DialogContent>
                        </Dialog>
                      </div>
                      <ToastContainer />
                      {invoiceFields.map((field) => (
                        <div
                          key={field.id}
                          className="data-report d-flex align-items-center mb-2 justify-content-between"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div className="flex-grow-1 me-2 d-flex align-items-center">
                            <label className="label">Vendor Department</label>
                            <input
                              type="text"
                              className="input ms-2"
                              value={field.vendorDepartment}
                              readOnly
                            />
                          </div>
                          <InvoiceAmountField
                            field={field}
                            onAmountSubmit={handleInvoiceFieldChange}
                          />
                          <button
                            className="btn btn-danger  ms-3"
                            onClick={() => removeInvoiceField(field.id)}
                          >
                            Remove
                          </button>
                        </div>
                      ))}

                      {invoiceFields.length > 0 && (
                        <div className="data-report">
                          <label className="label">Total Invoice Amount</label>
                          <input
                            type="text"
                            step="any"
                            className="input"
                            value={calculateTotalInvoiceAmount()}
                            readOnly
                            onInput={(e) => {
                              e.target.value = e.target.value
                                .replace(/[^0-9.]/g, "")
                                .replace(/^(\d*\.?\d{0,2}).*/g, "$1")
                                .slice(0, 12);
                            }}
                          />
                        </div>
                      )}

                      <div className="data-report">
                        <div className="col-md-12 d-flex justify-content-around">
                          <button
                            className="btn btn-contained m-2"
                            onClick={() => navigate("/purchase")}
                            style={{ color: "#4545DB" }}
                          >
                            Purchase Invoice
                          </button>
                          <button
                            className="btn btn-contained m-2"
                            onClick={() => navigate("/expense")}
                            style={{ color: "#4545DB" }}
                          >
                            Expense Invoices
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <table className="table table-hover align-middle mb-0">
                    <thead className="table-light">
                      <tr style={{ textAlign: "center" }}>
                        <th
                          style={{
                            alignContent: "center",
                            border: "none",
                            borderTopLeftRadius: "30px",
                            borderBottomLeftRadius: "30px",
                            textAlign: "center",
                          }}
                        >
                          Vendor Name
                        </th>
                        <th
                          style={{
                            alignContent: "center",
                            border: "none",
                            textAlign: "center",
                          }}
                        >
                          Invoice No
                        </th>
                        <th
                          style={{
                            alignContent: "center",
                            border: "none",
                            textAlign: "center",
                          }}
                        >
                          Amount
                        </th>
                        <th
                          className="text-center"
                          style={{
                            alignContent: "center",
                            border: "none",
                            borderTopRightRadius: "30px",
                            borderBottomRightRadius: "30px",
                            textAlign: "center",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {}
                      {shiftDetails &&
                      Array.isArray(shiftDetails.purchase_expenses) &&
                      shiftDetails.purchase_expenses.length === 0 ? (
                        <tr>
                          <td colSpan="3" className="text-center">
                            No data available
                          </td>
                        </tr>
                      ) : shiftDetails &&
                        Array.isArray(shiftDetails.purchase_expenses) &&
                        shiftDetails.purchase_expenses.length > 0 ? (
                        shiftDetails.purchase_expenses.filter(
                          (entry) => entry.type === "purchase_expense"
                        ).length === 0 ? (
                          <tr>
                            <td colSpan="3" className="text-center">
                              No data available for purchase expenses
                            </td>
                          </tr>
                        ) : (
                          shiftDetails.purchase_expenses
                            .filter(
                              (entry) => entry.type === "purchase_expense"
                            )
                            .map((expense) => (
                              <tr key={expense.id}>
                                <td className="text-center">
                                  <span
                                    className="fw-bold"
                                    style={{ color: "#212529" }}
                                  >
                                    {expense.title_id || "N/A"}
                                  </span>
                                </td>
                                <td className="text-center">
                                  <span className="text-success fw-semibold">
                                    {expense.invoice_no
                                      ? expense.invoice_no
                                      : "N/A"}
                                  </span>
                                </td>
                                <td className="text-center">
                                  <span className="text-success fw-semibold">
                                    ${parseFloat(expense.amount).toFixed(2)}
                                  </span>
                                </td>
                                <td className="text-center">
                                  <div className="dropdown">
                                    <button
                                      className="btn btn-outline-secondary btn-sm"
                                      type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="bi bi-three-dots-vertical"></i>
                                    </button>
                                    <ul className="dropdown-menu">
                                      <li>
                                        <button
                                          className="dropdown-item text-danger"
                                          onClick={() =>
                                            handleDeleteInvoice(expense.id)
                                          }
                                        >
                                          <i className="bi bi-trash me-2"></i>
                                          Delete
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            ))
                        )
                      ) : (
                        <tr>
                          <td colSpan="3" className="text-center">
                            No data available for purchase expenses
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="data-report">
                    <label htmlFor="payroll" className="label">
                      Select Payroll
                    </label>
                    <select
                      id="payroll"
                      className="input"
                      value={selectedPayroll ? selectedPayroll.id : ""}
                      onChange={handlePayrollSelect}
                    >
                      <option value="">Choose Payroll</option>
                      {availablePayrollEntries.map((payroll) => (
                        <option key={payroll.id} value={payroll.id}>
                          {payroll.payroll_employee_name} - $
                          {payroll.total_amount}
                        </option>
                      ))}
                    </select>
                  </div>
                  {selectedPayroll && (
                    <div className="data-report d-flex align-items-center mb-2">
                      <div className="flex-grow-1 me-2">
                        <label className="label">Employee Name</label>
                        <input
                          type="text"
                          className="input"
                          value={selectedPayroll.payroll_employee_name}
                          readOnly
                        />
                      </div>
                      <div className="flex-grow-1 me-2">
                        <label className="label">Total Amount</label>
                        <input
                          type="text"
                          step="any"
                          className="input"
                          defaultValue={selectedPayroll.total_amount}
                          onBlur={(e) =>
                            handlePayrollAmountChange(e, selectedPayroll)
                          }
                          onInput={(e) => {
                            e.target.value = e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/^(\d*\.?\d{0,2}).*/g, "$1")
                              .slice(0, 12);
                          }}
                          placeholder="Enter amount"
                        />
                      </div>
                      <div className="flex-grow-1 me-2">
                        <label className="label">Pay Method</label>
                        <input
                          type="text"
                          className="input"
                          value={selectedPayroll.pay_method}
                          readOnly
                        />
                      </div>
                    </div>
                  )}

                  <table className="table table-hover align-middle mb-0">
                    <thead className="table-light">
                      <tr style={{ textAlign: "center" }}>
                        <th
                          style={{
                            alignContent: "center",
                            border: "none",
                            borderTopLeftRadius: "30px",
                            borderBottomLeftRadius: "30px",
                            textAlign: "center",
                          }}
                        >
                          Employee Name
                        </th>
                        <th
                          style={{
                            alignContent: "center",
                            border: "none",
                            textAlign: "center",
                          }}
                        >
                          Description
                        </th>
                        <th
                          style={{
                            alignContent: "center",
                            border: "none",
                            textAlign: "center",
                          }}
                        >
                          Amount
                        </th>
                        <th
                          className="text-center"
                          style={{
                            alignContent: "center",
                            border: "none",
                            borderTopRightRadius: "30px",
                            borderBottomRightRadius: "30px",
                            textAlign: "center",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {}
                      {filteredExpenses.length === 0 ? (
                        <tr>
                          <td colSpan="3" className="text-center">
                            No data available
                          </td>
                        </tr>
                      ) : (
                        filteredExpenses.map((payrollEntry) => (
                          <tr key={payrollEntry.id}>
                            <td className="text-center">
                              <span
                                className="fw-bold"
                                style={{ color: "#212529" }}
                              >
                                {payrollEntry.title_id || "N/A"}
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="text-success fw-semibold">
                                Salary
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="text-success fw-semibold">
                                ${parseFloat(payrollEntry.amount).toFixed(2)}
                              </span>
                            </td>
                            <td className="text-center">
                              <div className="dropdown">
                                <button
                                  className="btn btn-outline-secondary btn-sm"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="bi bi-three-dots-vertical"></i>
                                </button>
                                <ul className="dropdown-menu">
                                  <li>
                                    <button
                                      className="dropdown-item text-danger"
                                      onClick={() =>
                                        handleDeletePayrollEntry(payrollEntry)
                                      }
                                    >
                                      <i className="bi bi-trash me-2"></i>Delete
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {shouldDisplayComponent(shiftDetails.lottery_report) && (
        <>
          <div className="mt-4">
            <p className="report-table-title">Daily Lottery Report</p>
          </div>
          <div className="row business-title-header">
            <div className="col-md-4">
              <p className="business-report-title desktop-title">
                Today Invoice
              </p>
            </div>
            <div className="col-md-4">
              <p className="business-report-title desktop-title">
                Yesterday Invoice
              </p>
            </div>
            <div className="col-md-4">
              <p className="business-report-title desktop-title">Today Cash</p>
            </div>
          </div>
          <div className="row business-report">
            <LotteryActivationTab
              shiftDetails={shiftDetails}
              lottery_report={lottery_report}
              handleLotteryFieldChange={handleLotteryFieldChange}
              renderLotteryRegisterInputs={renderLotteryRegisterInputs}
              isManualEnabled={isManualEnabled}
              manualValue={manualValue}
              setManualValue={setManualValue}
            />
          </div>
        </>
      )}

      {shouldDisplayComponent(shiftDetails.business_report) && (
        <>
          <div className="mt-4">
            <p className="report-table-title">Lottery Activated Books</p>
          </div>
          <div className="row business-title-header">
            <div className="col-md-4">
              <p className="business-report-title desktop-title">
                Scan Code and Activate
              </p>
            </div>
            <div className="col-md-4">
              <p className="business-report-title desktop-title">
                Activate Manually
              </p>
            </div>
            <div className="col-md-4">
              <p className="business-report-title desktop-title">Return Book</p>
            </div>
          </div>
          <div className="row business-report">
            <div className="col-md-4">
              <div className="col-md-4">
                <p className="business-report-title mobile-title">
                  Scan Code and Activate
                </p>
              </div>
              <div className="data-report2">
                <div className="input-lottery-container">
                  <input
                    type="text"
                    id="gross-sales"
                    className="input-lottery"
                    placeholder="Scan Number"
                    value={scanNumber}
                    onChange={handleScanNumberChange}
                  />
                  <div className="svg-icon">
                    <LottoIcon />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="col-md-4">
                <p className="business-report-title mobile-title">
                  Activate Manually
                </p>
              </div>
              <div className="data-report2">
                <input
                  type="text"
                  id="gross-sales"
                  className="input-game field"
                  placeholder="Game Number"
                  value={gameNo}
                  onChange={handleGameNoChange}
                />
              </div>
              <div className="data-report2">
                <input
                  type="text"
                  id="gross-sales"
                  className="input-game field"
                  placeholder="Book Number"
                  value={bookNo}
                  onChange={handleBookNoChange}
                />
                <button className="btn-activate" onClick={handleActivate}>
                  Activate
                </button>
              </div>
            </div>
            <div className="col-md-4">
              <div className="col-md-4">
                <p className="business-report-title mobile-title">
                  Return Book
                </p>
              </div>
              <div className="data-report2">
                <input
                  type="text"
                  id="gross-sales"
                  className="input-game field"
                  placeholder="Game Number"
                  value={gameReturnNo}
                  onChange={handleGameReturnNoChange}
                />
              </div>
              <div className="data-report2">
                <input
                  type="text"
                  className="input-game field"
                  placeholder="Book Number"
                  value={bookReturnNo}
                  onChange={handleBookNoReturnChange}
                />
              </div>
              <div className="data-report2">
                <input
                  type="text"
                  className="input-game field"
                  placeholder="Ticket Number"
                  value={ticketNo}
                  onChange={handleTicketNoChange}
                />
                <button className="btn-activate" onClick={handleReturn}>
                  Return Now
                </button>
              </div>
            </div>
          </div>
          &nbsp;&nbsp;
          <div className="row business-title-header">
            <div
              className={`col-md-2 ${
                activeTab === "activated" ? "active-tab" : "disabled-tab"
              }`}
              onClick={() => handleTabClick("activated")}
            >
              <p
                className={`business-report-title return-book-title ${
                  activeTab === "activated" ? "active" : ""
                }`}
              >
                Activated Books
              </p>
            </div>
            <div
              className={`col-md-2 ${
                activeTab === "returned" ? "active-tab" : "disabled-tab"
              }`}
              onClick={() => handleTabClick("returned")}
            >
              <p
                className={`business-report-title  return-book-title ${
                  activeTab === "returned" ? "active" : ""
                }`}
              >
                Returned Books
              </p>
            </div>
          </div>
          <div className="">
            <LotteryReportTable
              key={`lotto-table-${activeTab}-${reloadLottoTable}`}
              lottoData={
                activeTab === "activated" ? activatedBooks : returnedBooks
              }
              columns={
                activeTab === "activated" ? activatedColumns : returnedColumns
              }
              showFooter={true}
              reloadLottoTable={reloadLottoTable}
            />
          </div>
          <div className="mt-4">
            <p className="report-table-title">Lottery Ticket Scan</p>
          </div>
          <div className="row business-title-header">
            <div className="col-md-4">
              <p className="business-report-title desktop-title">
                Scan Code Here
              </p>
            </div>
            <div className="col-md-4">
              <p className="business-report-title desktop-title">
                Add Ticket Manually
              </p>
            </div>
          </div>
          <div className="row business-report">
            <LotteryManualTicketScan
              scanNumber={scanNumber}
              setScanNumber={setScanNumber}
              gameTicketScanNo={gameTicketScanNo}
              setGameTicketScanNo={setGameTicketScanNo}
              bookTicketScanNo={bookTicketScanNo}
              setBookTicketScanNo={setBookTicketScanNo}
              ticketScanNo={ticketScanNo}
              setTicketScanNo={setTicketScanNo}
              lastTicketNo={lastTicketNo}
              handleScanNumberChange={handleScanNumberChange}
              handleGameTicketScanChange={handleGameTicketScanChange}
              handleBookTicketScanChange={handleBookTicketScanChange}
              handleTicketScanChange={handleTicketScanChange}
              handleLotteryTicketScan={handleLotteryTicketScan}
            />
          </div>
          <div className="mt-4"></div>
          {/* <div className="mt-4">
            <p className="report-table-title">Scanned Tickets</p>
          </div> */}
          <div className="row business-title-header">
            <div
              className={`col-md-2 ${
                activeTab === "activated" ? "active-tab" : "disabled-tab"
              }`}
              onClick={() => handleTabClick("activated")}
            >
              <p
                className={`business-report-title ${
                  activeTab === "activated" ? "active" : ""
                }`}
              >
                Scanned Tickets
              </p>
            </div>
          </div>
          <div className="table-container">
            <LotteryReportTable
              key={`scanned-tickets-${reloadLottoTable}`}
              lottoData={SetHistory}
              columns={ScanedTicketColumnsAllOver}
              showFooter={true}
              reloadLottoTable={reloadLottoTable}
            />
          </div>
        </>
      )}

      <ReusableModal2
        show={showEmailModal}
        handleClose={() => setShowEmailModal(false)}
        title="Email Daily Report"
      >
        <form>
          <div className="mb-3">
            <p>The generated report will be sent to you and the store owner.</p>
          </div>

          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-contained me-2"
              onClick={() => setShowEmailModal(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSendEmail}
            >
              Send Report
            </button>
          </div>
        </form>
      </ReusableModal2>
    </div>
  );
}

export default BusinessReport;
