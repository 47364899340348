/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

const HouseChargeTable = ({
  data,
  columns,
  showAction,
  showFooter,
  onEdit,
  onDelete,
  showExport,
  showFilter,
  handleEnableDisable,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const totalPages = Math.ceil(data.length / rowsPerPage);

  const getDisplayedData = () => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const filterData = (dataToFilter) => {
    if (!searchTerm) return dataToFilter;
    return dataToFilter.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  const sortDisplayedData = (dataToSort) => {
    let sortableItems = [...dataToSort];
    if (sortConfig.key) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  };

  const displayedData = getDisplayedData();
  const filteredData = filterData(displayedData);
  const sortedDisplayedData = sortDisplayedData(filteredData);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <a
            className="page-link"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setCurrentPage(i);
            }}
          >
            {i}
          </a>
        </li>
      );
    }
    return pages;
  };

  const statusStyles = {
    enable: {
      backgroundColor: "#6CBE1C", // Green background for Enable
      color: "#fff",
      borderRadius: "30px",
      padding: "5px 10px",
    },
    disable: {
      backgroundColor: "#F6F6F6", // Red background for Disable
      color: "#fff",
      borderRadius: "30px",
      padding: "5px 10px",
    },
    draft: {
      backgroundColor: "#D1D1EF",
      color: "#fff",
      borderRadius: "30px",
      padding: "5px 10px",
    },
    unpaid: {
      backgroundColor: "#EF3E49",
      color: "#fff",
      borderRadius: "30px",
      padding: "5px 10px",
    },
    paid: {
      backgroundColor: "#5856AC",
      color: "#fff",
      borderRadius: "30px",
      padding: "5px 10px",
    },
  };

  const downloadCSV = () => {
    const headers = columns.map((col) => col.header).join(",") + "\n";
    const rows = data
      .map((row) =>
        columns.map((col) => String(row[col.field] || "")).join(",")
      )
      .join("\n");

    const csvContent = headers + rows;
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const [showDropdown, setShowDropdown] = useState(false);
  const handleFilterClick = () => {
    setShowDropdown(!showDropdown); // Toggle the dropdown
  };

  const renderFilterDropdown = () => {
    return (
      <div className="filter-dropdown" style={dropdownStyles}>
        <label>
          <input type="checkbox" /> Option 1
        </label>
        <label>
          <input type="checkbox" /> Option 2
        </label>
        <label>
          <input type="checkbox" /> Option 3
        </label>
        <button onClick={() => setShowDropdown(false)}>Apply Filter</button>
      </div>
    );
  };

  const dropdownStyles = {
    position: "absolute",
    top: "100%",
    left: "0",
    backgroundColor: "white",
    border: "1px solid #ddd",
    padding: "10px",
    boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
    borderRadius: "4px",
    zIndex: "1",
  };
  // Calculate the total net sales from the data
  //  const totalNetSales = data.reduce((acc, item) => {
  //   const amount = Number(item.total_net_sales) || 0; // Make sure to handle non-numeric values
  //   return acc + amount;
  // }, 0).toFixed(2); //
  return (
    <div className="data-table-container">
      <div className="header-row">
          <div className="setting-title">House Charges</div>
        </div>
      <div className="d-flex mb-3">
        <div className="expense-searchcontainerstart d-flex">
          <div className="search-container">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search"
            />
            <svg
              className="search-container-icon"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5416 19.2497C15.3511 19.2497 19.2499 15.3508 19.2499 10.5413C19.2499 5.73186 15.3511 1.83301 10.5416 1.83301C5.73211 1.83301 1.83325 5.73186 1.83325 10.5413C1.83325 15.3508 5.73211 19.2497 10.5416 19.2497Z"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.1666 20.1663L18.3333 18.333"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          {showExport && ( // Conditionally render the button based on showExport prop
            <button
              type="button"
              className="btn btn-outline-dark custbtn"
              style={{ borderRadius: "10px", border: "1px solid #f4f4f4" }}
              onClick={downloadCSV}
            >
              <svg
                style={{ marginRight: "5px" }}
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.98975 4.87312L8.90975 2.95312L10.8297 4.87312"
                  stroke="#4545DB"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.91016 10.6353V3.00781"
                  stroke="#4545DB"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 9C3 12.315 5.25 15 9 15C12.75 15 15 12.315 15 9"
                  stroke="#4545DB"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Export To Excel
            </button>
          )}
          {showFilter && (
            <div
              className="filter-button-container"
              style={{ position: "relative" }}
            >
              <button
                className="btn custbtn2"
                type="button"
                onClick={handleFilterClick}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: "3px" }}
                >
                  <g opacity="0.8">
                    <path
                      d="M19 3H5C3.586 3 2.879 3 2.44 3.412C2.001 3.824 2 4.488 2 5.815V6.505C2 7.542 2 8.061 2.26 8.491C2.52 8.921 2.993 9.189 3.942 9.723L6.855 11.363C7.491 11.721 7.81 11.9 8.038 12.098C8.512 12.509 8.804 12.993 8.936 13.588C9 13.872 9 14.206 9 14.873V17.543C9 18.452 9 18.907 9.252 19.261C9.504 19.616 9.952 19.791 10.846 20.141C12.725 20.875 13.664 21.242 14.332 20.824C15 20.406 15 19.452 15 17.542V14.872C15 14.206 15 13.872 15.064 13.587C15.1896 13.0042 15.5059 12.4798 15.963 12.097C16.19 11.9 16.509 11.721 17.145 11.362L20.058 9.722C21.006 9.189 21.481 8.922 21.74 8.492C22 8.062 22 7.542 22 6.504V5.814C22 4.488 22 3.824 21.56 3.412C21.122 3 20.415 3 19 3Z"
                      stroke="white"
                      strokeWidth="1.5"
                    />
                  </g>
                </svg>
                Filter
              </button>
              {showDropdown && renderFilterDropdown()}
            </div>
          )}
        </div>
        <div className="expense-searchcontainerend d-flex align-items-center">
          <h3 className="me-2">Show</h3>
          <select
            name="option"
            id="pageSelect"
            className="selectoptions"
            value={rowsPerPage}
            onChange={(e) => {
              setRowsPerPage(Number(e.target.value));
              setCurrentPage(1); // Reset to first page on change
            }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
          </select>
        </div>
      </div>
      <div className="table-container">
      <div className="w-100">
      <table className="data-table custom-table rounded-table">
        <thead className="table-header">
          <tr>
            <th scope="col" className="no-column">
              No
            </th>
            {columns.map((col, index) => (
              <th key={index} scope="col" onClick={() => handleSort(col.field)}>
                {col.header}
                {sortConfig.key === col.field &&
                  (sortConfig.direction === "ascending" ? (
                    <svg
                      width="8"
                      height="4"
                      viewBox="0 0 8 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginLeft: "5px" }}
                    >
                      <path d="M0 4L4 0L8 4H0Z" fill="#002300" />
                    </svg>
                  ) : (
                    <svg
                      width="8"
                      height="4"
                      viewBox="0 0 8 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginLeft: "5px" }}
                    >
                      <path d="M0 0L4 4L8 0H0Z" fill="#002300" />
                    </svg>
                  ))}
              </th>
            ))}
            {showAction && (
              <th scope="col" className="action-column">
                Action
              </th>
            )}
          </tr>
        </thead>
        <tbody className="table-body">
          {sortedDisplayedData.length === 0 ? (
            <tr>
              <td
                colSpan={columns.length + (showAction ? 2 : 1)}
                style={{
                  textAlign: "center",
                  fontFamily: "Manrope",
                  fontWeight: "600",
                  fontSize: "40px",
                  background:
                    "linear-gradient(180deg, rgba(255, 255, 255, 0.1) -92.86%, #4545DB 71.43%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                No data found
              </td>
            </tr>
          ) : (
            sortedDisplayedData.map((item) => (
              <tr key={item.id || item.index}>
                <td>
                  {(currentPage - 1) * rowsPerPage +
                    sortedDisplayedData.indexOf(item) +
                    1}
                </td>
                {columns.map((col, colIndex) => (
                  <td key={colIndex}>
                    {col.field === "status" ? (
                      <div style={statusStyles[item[col.field]]}>
                        {typeof item[col.field] === "string" && item[col.field]
                          ? item[col.field].charAt(0).toUpperCase() +
                            item[col.field].slice(1)
                          : "-"}
                      </div>
                    ) : item[col.field] !== undefined &&
                      item[col.field] !== null ? (
                      typeof item[col.field] === "function" ? (
                        item[col.field]()
                      ) : (
                        item[col.field]
                      )
                    ) : (
                      "-"
                    )}
                  </td>
                ))}
                {showAction && (
                  <td className="action-column">
                    {/* <button
                      className="action-button"
                      onClick={() => onEdit(item)}
                    >

                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.8067 4.695C14.0667 4.435 14.0667 4.00167 13.8067 3.755L12.2467 2.195C12 1.935 11.5667 1.935 11.3067 2.195L10.08 3.415L12.58 5.915M2 11.5017V14.0017H4.5L11.8733 6.62167L9.37333 4.12167L2 11.5017Z"
                          fill="#002300"
                        />
                      </svg>
                    </button>
                    <button
                      className="action-button"
                      onClick={() => onDelete(item.id)}
                    >

                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.00016 12.6667C4.00016 13.4 4.60016 14 5.3335 14H10.6668C11.4002 14 12.0002 13.4 12.0002 12.6667V6C12.0002 5.26667 11.4002 4.66667 10.6668 4.66667H5.3335C4.60016 4.66667 4.00016 5.26667 4.00016 6V12.6667ZM12.0002 2.66667H10.3335L9.86016 2.19333C9.74016 2.07333 9.56683 2 9.3935 2H6.60683C6.4335 2 6.26016 2.07333 6.14016 2.19333L5.66683 2.66667H4.00016C3.6335 2.66667 3.3335 2.96667 3.3335 3.33333C3.3335 3.7 3.6335 4 4.00016 4H12.0002C12.3668 4 12.6668 3.7 12.6668 3.33333C12.6668 2.96667 12.3668 2.66667 12.0002 2.66667Z"
                          fill="#002300"
                        />
                      </svg>
                    </button> */}
                    <button
                      className="action-button"
                      onClick={() => handleEnableDisable(item.id, item.is_paid)}
                      style={{
                        width: "145px",
                        height: "34px",
                        borderRadius: "40px", // Rounded corners
                        backgroundColor: item.is_paid ? "#6c757d" : "#6CBE1C", // Grey if `is_paid` is true, green if false
                        color: item.is_paid ? "#000" : "#fff", // Black text for grey background, white for green
                        padding: "1px 10px", // Spacing for better UI
                        border: "none", // No border
                        cursor: "pointer", // Pointer cursor
                        transition: "background-color 0.3s ease", // Smooth color transition
                        fontSize: "18px", // Text size
                      }}
                    >
                      {item.is_paid ? "Transaction" : "Mark as Paid"}
                    </button>
                  </td>
                )}
              </tr>
            ))
          )}
        </tbody>
      </table>
      </div></div>
      {showFooter && (
        <nav>
          <ul className="pagination">
            <li className={`prev-next ${currentPage === 1 ? "disabled" : ""}`}>
              <span
                style={{
                  fontSize: "24px",
                  width: "40px",
                  height: "40px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  borderRadius: "50%",
                  border: "1px solid #bfbfbfbf",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
                onClick={handlePrev}
              >
                &lt;
              </span>
              &nbsp;
            </li>
            {renderPagination()}
            <li
              className={`prev-next ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              &nbsp;
              <span
                style={{
                  fontSize: "24px",
                  width: "40px",
                  height: "40px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  borderRadius: "50%",
                  border: "1px solid #bfbfbfbf",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
                onClick={handleNext}
              >
                &gt;{" "}
              </span>
            </li>
          </ul>
        </nav>
      )}
        <style jsx>{`
        .table-container {
          width: 100%;
          overflow-x: auto;
        }

        /* Hide scrollbar on desktop */
        @media (min-width: 992px) {
          .table-container {
            overflow-x: visible;
          }
        }

        /* Show horizontal scrollbar only on mobile/tablet */
        @media (max-width: 991px) {
          .table-container {
            overflow-x: auto;
          }

          /* For better mobile experience, ensure table takes full width */
          .table {
            min-width: 800px;
          }
        }
      `}</style>
    </div>
  );
};

export default HouseChargeTable;
