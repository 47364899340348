import React from "react";
import PropTypes from "prop-types";
import backgroundimg from "../../assets/img/BackgroundBody.png";
import { Link } from "react-router-dom";
import "./Formlayout.css"; 

const FormLayout = ({ logoSrc, backgroundImageSrc, children, TopImage }) => {
  return (
    <div className="form-layout-container" style={{ backgroundImage: `url('${backgroundimg}')` }}>
      <div className="form-layout-wrapper">
        <div className="form-content">
          <div className="logo-container">
            <Link to="/login">
              <img src={logoSrc} alt="Logo" className="logo-image" />
            </Link>
          </div>
          <div className="form-inner">
            {children}
          </div>
        </div>

        <div className="form-background">
          <img
            src={TopImage}
            alt="Top Background"
            className="top-image"
          />
          <img
            src={backgroundImageSrc}
            alt="Main Background"
            className="main-background-image"
          />
        </div>
      </div>
    </div>
  );
};

FormLayout.propTypes = {
  logoSrc: PropTypes.string.isRequired,
  backgroundImageSrc: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  TopImage: PropTypes.string.isRequired,
};

export default FormLayout;