/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import useApi from '../../../../../utils/api-manager/Helper/useApi';
import { MoreHoriz } from '@mui/icons-material';
import { toast, ToastContainer } from "react-toastify";

const ExpenseBreakdown = () => {
  const [vendors, setVendors] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { Post } = useApi();

  // Add window resize listener
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => { 
    fetchExpenseData();
  }, []);
 
  const fetchExpenseData = async () => {
    try {
      const response = await Post('dashboardExpense');

      if (Array.isArray(response?.data?.top_vendors)) {
        setVendors(response.data.top_vendors);
      } else {
        console.error("Expected top_vendors to be an array.");
        toast.error("Error: Expected top vendors data to be an array.");
      }
    } catch (error) {
      console.error("Error fetching expense data:", error);
      toast.error("Error fetching expense data: " + (error.response ? error.response.data : error.message));
    }
  };

  // Calculate combined percentage
  const combinedPercentage = vendors.reduce((total, vendor) => total + vendor.percentage, 0) / vendors.length;

  // Responsive styles
  const containerStyle = {
    maxWidth: '513px',
    width: windowWidth < 768 ? '100%' : windowWidth < 992 ? '100%' : '100%', // Full width for screens < 768 and < 992
    height: windowWidth < 576 ? 'auto' : '250px',
    minHeight: '200px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: windowWidth < 576 ? '12px' : '16px',
    marginTop: '20px',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    marginLeft: windowWidth < 992 ? 'auto' : '0', // Centering the div on < 992px
    marginRight: windowWidth < 992 ? 'auto' : '0', // Centering the div on < 992px
    display: windowWidth < 992 ? 'block' : 'inline-block', // Block display to center on smaller screens
  };
  

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: windowWidth < 576 ? '12px' : '16px',
  };

  const vendorContainerStyle = {
    marginTop: windowWidth < 576 ? '12px' : '16px',
    display: 'flex',
    flexDirection: windowWidth < 480 && vendors.length > 2 ? 'column' : 'row',
    gap: windowWidth < 576 ? '8px' : '12px',
  };

  const vendorItemStyle = (index) => ({
    color: 'black',
    borderLeft: index === 0 && windowWidth >= 480 ? 'none' : '1px solid gray',
    padding: '8px 0',
    textAlign: 'left',
    flex: 1,
    display: 'flex',
    flexDirection: windowWidth < 480 ? 'row' : 'column',
    justifyContent: windowWidth < 480 ? 'space-between' : 'flex-start',
    alignItems: windowWidth < 480 ? 'center' : 'flex-start',
    gap: windowWidth < 480 ? '0' : '8px',
    paddingLeft: '8px',
    marginBottom: windowWidth < 480 && vendors.length > 2 ? '8px' : '0',
  });

  const progressBarStyle = {
    marginTop: windowWidth < 576 ? '12px' : '16px',
    height: windowWidth < 576 ? '60px' : '90px',
    width: '100%',
    display: 'flex',
  };

  return (
    <div style={containerStyle}>
      <ToastContainer />
      {/* Header */}
      <div style={headerStyle}>
        <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: windowWidth < 576 ? '16px' : '18px' }}>Expense Breakdown</h6>
        <button
          style={{
            background: 'none',
            border: 'none',
            color: '#999',
            fontSize: windowWidth < 576 ? '16px' : '18px',
            cursor: 'pointer',
          }}
        >
          <MoreHoriz />
        </button>
      </div>

      {/* Vendor Data */}
      <div style={vendorContainerStyle}>
        {Array.isArray(vendors) && vendors.length > 0 ? (
          vendors.map((vendor, index) => (
            <div
              key={vendor.vendor_name}
              style={vendorItemStyle(index)}
            >
              {/* Vendor Name */}
              <p style={{ 
                margin: '0', 
                fontSize: windowWidth < 576 ? '12px' : '14px',
                fontWeight: '500', 
                color: 'gray',
                flex: windowWidth < 480 ? '1' : 'auto'
              }}>
                {vendor.vendor_name}
              </p>
              {/* Vendor Percentage */}
              <p style={{
                margin: '0',
                fontSize: windowWidth < 576 ? '16px' : '20px',
                fontWeight: '900',
                color: 'black',
              }}>
                {vendor.percentage}%
              </p>
            </div>
          ))
        ) : (
          <p style={{ margin: '0 auto', color: '#999' }}>No vendors available</p>
        )}
      </div>

      {/* Stacked Progress Bar */}
      <div style={progressBarStyle}>
        {Array.isArray(vendors) && vendors.length > 0 ? (
          vendors.map((vendor, index) => {
            // Define styles for each vendor
            let backgroundStyle = '';
            if (index === 0) {
              backgroundStyle = 'rgba(0, 0, 255, 0.5)'; // Blue color for the first vendor
            } else if (index === 1) {
              backgroundStyle = 'repeating-linear-gradient(90deg, lightgray 0, lightgray 5px, transparent 5px, transparent 10px)'; // Light gray with repeating gradient for the second
            } else if (index === 2) {
              backgroundStyle = 'repeating-linear-gradient(90deg, black 0, black 5px, transparent 5px, transparent 10px)'; // Black with repeating gradient for the third
            }

            return (
              <div
                key={vendor.vendor_name}
                style={{
                  width: `${vendor.percentage}%`,
                  background: backgroundStyle,
                  height: '100%',
                  textAlign: 'center',
                  lineHeight: '20px',
                  color: '#fff',
                }}
              />
            );
          })
        ) : (
          <div style={{ width: '100%', textAlign: 'center', color: '#999' }}></div>
        )}
      </div>
    </div>
  );
};

export default ExpenseBreakdown;