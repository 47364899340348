import React from 'react';

export default function LotteryActivationTab({
  shiftDetails,
  lottery_report,
  handleLotteryFieldChange,
  renderLotteryRegisterInputs,
  isManualEnabled,
  manualValue,
  setManualValue,
}) {
  return (
    
    <div>
      <div className="row business-report">
        
        <div className="col-md-4">
        <div className="col-md-4">
              <p className="business-report-title mobile-title">Today Invoice</p>
            </div>
          {shiftDetails.lottery_register_reports?.length > 0 ? (
            <>
              {renderLotteryRegisterInputs(
                shiftDetails.lottery_register_reports,
                "net_sales_sr50",
                "Online Net Sales"
              )}
              {renderLotteryRegisterInputs(
                shiftDetails.lottery_register_reports,
                "online_cashing_sr50",
                "Online Cashing"
              )}
              {renderLotteryRegisterInputs(
                shiftDetails.lottery_register_reports,
                "instant_cashing_sr34",
                "Instant Cashing"
              )}
            </>
          ) : (
            <div className="data-report">No lottery register data available</div>
          )}

          <div className="data-report">
            <label htmlFor="total_net_sales_sr50" className="label">
              Total Net Sales SR50
            </label>
            <input
              type="text"
              step="any"
              id="total_net_sales_sr50"
              name="total_net_sales_sr50"
              className="input-readonly"
              value={lottery_report?.total_net_sales_sr50 || ""}
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .replace(/^(\d*\.?\d{0,2}).*/g, "$1")
                  .slice(0, 12);
              }}
              onChange={(e) =>
                handleLotteryFieldChange(
                  null,
                  "total_net_sales_sr50",
                  e.target.value,
                  false
                )
              }
              readOnly
            />
          </div>

          <div className="data-report">
            <label htmlFor="total_online_cashing_sr50" className="label">
              Total Online Cashing SR50
            </label>
            <input
              type="text"
              step="any"
              id="total_online_cashing_sr50"
              name="total_online_cashing_sr50"
              className="input-readonly"
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .replace(/^(\d*\.?\d{0,2}).*/g, "$1")
                  .slice(0, 12);
              }}
              value={lottery_report?.total_online_cashing_sr50 || ""}
              onChange={(e) =>
                handleLotteryFieldChange(
                  null,
                  "total_online_cashing_sr50",
                  e.target.value,
                  false
                )
              }
              readOnly
            />
          </div>

          <div className="data-report">
            <label htmlFor="total_instant_cashing_today" className="label">
              Total Instant Cashing Today
            </label>
            <input
              type="text"
              step="any"
              id="total_instant_cashing_today"
              name="total_instant_cashing_today"
              className="input-readonly"
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .replace(/^(\d*\.?\d{0,2}).*/g, "$1")
                  .slice(0, 12);
              }}
              value={lottery_report?.total_instant_cashing_today || ""}
              onChange={(e) =>
                handleLotteryFieldChange(
                  null,
                  "total_instant_cashing_today",
                  e.target.value,
                  false
                )
              }
              readOnly
            />
          </div>

          <div className="data-report">
            <label htmlFor="instant_sale_sr34" className="label">
              Instant Sale SR34
            </label>
            <input
              type="text"
              step="any"
              id="instant_sale_sr34"
              name="instant_sale_sr34"
              className="input"
              disabled={!isManualEnabled}
              style={{
                backgroundColor: !isManualEnabled ? "#f5f5f5" : "#f5f5f5",
              }}
              value={
                isManualEnabled
                  ? manualValue === ""
                    ? ""
                    : manualValue
                  : lottery_report?.instant_sale_sr34
              }
              onChange={(e) => {
                if (isManualEnabled) {
                  let val = e.target.value
                    .replace(/[^0-9.]/g, "")
                    .replace(/^(\d*\.?\d{0,2}).*/g, "$1")
                    .slice(0, 12);
                  setManualValue(val);
                }
              }}
              defaultValue={lottery_report?.instant_sale_sr34 || ""}
              onBlur={() => {
                if (isManualEnabled) {
                  handleLotteryFieldChange(
                    null,
                    "instant_sale_sr34",
                    manualValue || "",
                    false
                  );
                }
              }}
            />
          </div>
        </div>

        <div className="col-md-4">
        <div className="col-md-4">
              <p className="business-report-title mobile-title">Yesterday Invoice</p>
            </div>
          {shiftDetails.lottery_register_reports?.length > 0 ? (
            <>
              {renderLotteryRegisterInputs(
                shiftDetails.lottery_register_reports,
                "net_sales_sr51",
                "Online Net Sales"
              )}
              {renderLotteryRegisterInputs(
                shiftDetails.lottery_register_reports,
                "online_cashing_sr51",
                "Online Cashing"
              )}
              {renderLotteryRegisterInputs(
                shiftDetails.lottery_register_reports,
                "instant_cashing_sr35",
                "Instant Cashing"
              )}
            </>
          ) : (
            <div className="data-report">No lottery register data available</div>
          )}

          <div className="data-report">
            <label htmlFor="total_net_sales_sr51" className="label">
              Total Net Sales SR51
            </label>
            <input
              type="text"
              step="any"
              id="total_net_sales_sr51"
              name="total_net_sales_sr51"
              className="input-readonly"
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .replace(/^(\d*\.?\d{0,2}).*/g, "$1")
                  .slice(0, 12);
              }}
              value={lottery_report?.total_net_sales_sr51 || ""}
              onChange={(e) =>
                handleLotteryFieldChange(
                  null,
                  "total_net_sales_sr51",
                  e.target.value,
                  false
                )
              }
              readOnly
            />
          </div>

          <div className="data-report">
            <label htmlFor="total_online_cashing_sr51" className="label">
              Total Online Cashing SR51
            </label>
            <input
              type="text"
              step="any"
              id="total_online_cashing_sr51"
              name="total_online_cashing_sr51"
              className="input-readonly"
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .replace(/^(\d*\.?\d{0,2}).*/g, "$1")
                  .slice(0, 12);
              }}
              value={lottery_report?.total_online_cashing_sr51 || ""}
              onChange={(e) =>
                handleLotteryFieldChange(
                  null,
                  "total_online_cashing_sr51",
                  e.target.value,
                  false
                )
              }
              readOnly
            />
          </div>

          <div className="data-report">
            <label htmlFor="total_instant_cashing_yesterday" className="label">
              Total Instant Cashing Yesterday
            </label>
            <input
              type="text"
              step="any"
              id="total_instant_cashing_yesterday"
              name="total_instant_cashing_yesterday"
              className="input-readonly"
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .replace(/^(\d*\.?\d{0,2}).*/g, "$1")
                  .slice(0, 12);
              }}
              value={lottery_report?.total_instant_cashing_yesterday || ""}
              onChange={(e) =>
                handleLotteryFieldChange(
                  null,
                  "total_instant_cashing_yesterday",
                  e.target.value,
                  false
                )
              }
              readOnly
            />
          </div>
        </div>

        <div className="col-md-4">
        <div className="col-md-4">
              <p className="business-report-title mobile-title">Today Cash</p>
            </div>
          <div className="data-report">
            <label htmlFor="total_online_balance" className="label">
              Total Online Balance
            </label>
            <input
              type="text"
              step="any"
              id="total_online_balance"
              name="total_online_balance"
              className="input-readonly"
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .replace(/^(\d*\.?\d{0,2}).*/g, "$1")
                  .slice(0, 12);
              }}
              defaultValue={lottery_report?.total_online_balance || ""}
              onBlur={(e) =>
                handleLotteryFieldChange(
                  null,
                  "total_online_balance",
                  e.target.value,
                  false
                )
              }
            />
          </div>

          <div className="data-report">
            <label htmlFor="credit_sales" className="label">
              Credit Sales
            </label>
            <input
              type="text"
              step="any"
              id="credit_sales"
              name="credit_sales"
              className="input"
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .replace(/^(\d*\.?\d{0,2}).*/g, "$1")
                  .slice(0, 12);
              }}
              defaultValue={lottery_report?.credit_sales || ""}
              onBlur={(e) =>
                handleLotteryFieldChange(
                  null,
                  "credit_sales",
                  e.target.value,
                  false
                )
              }
            />
          </div>

          <div className="data-report">
            <label htmlFor="debit_sales" className="label">
              Debit Sales
            </label>
            <input
              type="text"
              step="any"
              id="debit_sales"
              name="debit_sales"
              className="input"
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .replace(/^(\d*\.?\d{0,2}).*/g, "$1")
                  .slice(0, 12);
              }}
              defaultValue={lottery_report?.debit_sales || ""}
              onBlur={(e) =>
                handleLotteryFieldChange(
                  null,
                  "debit_sales",
                  e.target.value,
                  false
                )
              }
            />
          </div>

          <div className="data-report">
            <label htmlFor="register_cash" className="label">
              Register Cash
            </label>
            <input
              type="text"
              step="any"
              id="register_cash"
              name="register_cash"
              className="input-readonly"
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .replace(/^(\d*\.?\d{0,2}).*/g, "$1")
                  .slice(0, 12);
              }}
              defaultValue={lottery_report?.register_cash || ""}
              onBlur={(e) =>
                handleLotteryFieldChange(
                  null,
                  "register_cash",
                  e.target.value,
                  false
                )
              }
            />
          </div>

          <div className="data-report">
            <label htmlFor="over_short" className="label">
              Over Short
            </label>
            <input
              type="text"
              step="any"
              id="over_short"
              name="over_short"
              className="input-readonly"
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .replace(/^(\d*\.?\d{0,2}).*/g, "$1")
                  .slice(0, 12);
              }}
              value={lottery_report?.over_short || ""}
              onBlur={(e) =>
                handleLotteryFieldChange(
                  null,
                  "over_short",
                  e.target.value,
                  false
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
