/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import LotteryInventoryTable from "./LotteryInventoryTable";
function BookMovement() {
  const [games, setGames] = useState([]);
  const { Get } = useApi();

  const fetchGameData = () => {
    Get("getBookMovement")
      .then((response) => {
        setGames(response.lottery_inventory);
      })
      .catch((error) => {
        console.error("Error fetching game data:", error);
      });
  };

  useEffect(() => {
    fetchGameData();
  }, []);

  const columns = [
    { header: "Date", field: "active_date" },
    { header: "Game No", field: "game_no" },
    { header: "Game Name", field: "game_name" },
    { header: "Game Price", field: "game_price" },
    { header: "Book No", field: "book_no" },
    { header: "Receive Date", field: "game_receive_date" },
    { header: "Activated Date", field: "active_date" },
    { header: "Settlement Date", field: "instant_settlement_date" },
    { header: "Status", field: "status" },
  ];

  return (
    <div className="due-days">
      <div className="header-row">
        <div className="setting-title">Book Movement</div>
      </div>
      <LotteryInventoryTable data={games} columns={columns} showFooter={true} />
    </div>
  );
}

export default BookMovement;
