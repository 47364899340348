import React from "react";
import { LottoIcon } from "../../../../utils/api-manager/Forms/SvgIcons";

export default function LotteryManualTicketScan({
  scanNumber,
  setScanNumber,
  gameTicketScanNo,
  setGameTicketScanNo,
  bookTicketScanNo,
  setBookTicketScanNo,
  ticketScanNo,
  setTicketScanNo,
  lastTicketNo,
  handleScanNumberChange,
  handleGameTicketScanChange,
  handleBookTicketScanChange,
  handleTicketScanChange,
  handleLotteryTicketScan,
}) {
  return (
    <div>
      <div className="row business-report">
        {/* First Column for Scan Number */}
        <div className="col-md-4">
          <div className="col-md-4">
            <p className="business-report-title mobile-title">Scan Code Here</p>
          </div>
          <div className="data-report">
            <div className="input-lottery-container">
              <input
                type="text"
                id="gross-sales"
                className="input-lottery"
                placeholder="Scan Number"
                value={scanNumber}
                onChange={handleScanNumberChange} // Using handler passed via props
              />
              <div className="svg-icon">
                <LottoIcon />
              </div>
            </div>
          </div>
        </div>

        {/* Second Column for Game, Book, and Ticket Number */}
        <div className="col-md-4">
          <div className="col-md-4">
            <p className="business-report-title mobile-title">
              Add Ticket Manually
            </p>
          </div>
          <div className="data-report">
            <input
              type="text"
              id="gross-sales"
              className="input-game field"
              placeholder="Game Number"
              value={gameTicketScanNo}
              onChange={handleGameTicketScanChange} // Using handler passed via props
            />
          </div>
          <div className="data-report">
            <input
              type="text"
              className="input-game field"
              placeholder="Book Number"
              value={bookTicketScanNo}
              onChange={handleBookTicketScanChange} // Using handler passed via props
            />
          </div>
          <div className="data-report2">
            <input
              type="text"
              className="input-game field"
              placeholder="Ticket Number"
              value={ticketScanNo}
              onChange={handleTicketScanChange} // Using handler passed via props
            />
            <button className="btn-scanning" onClick={handleLotteryTicketScan}>
              Finish Scanning
            </button>
          </div>
        </div>

        {/* Third Column for Last Ticket Count */}
        <div className="col-md-4 d-flex justify-content-center">
          <p className="count-ticket">
            Count: ({lastTicketNo}) Last Ticket Scanned:
          </p>
        </div>
      </div>
    </div>
  );
}
