/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import ReusableModal from "./ReusableModal";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import AuthContext from "../../../../utils/secure-route/AuthContext";
import "./Settings.css";
import SettingTable from "./SettingTable";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";

function ManageExpenseType() {
  let { user } = useContext(AuthContext);
  const storeId = user.store;
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedExpenseType, setSelectedExpenseType] = useState(null);
  const [expenseType, setExpenseType] = useState("");
  const [expenseTypes, setExpenseTypes] = useState([]);
  const { Get, Post, Put, Delete } = useApi();
  useEffect(() => {
    Get("vendorDepartmentServiceData")
      .then((response) => {
        setExpenseTypes(response);
      })
      .catch((error) => {
        console.error("Error fetching expense types:", error);
      });
  }, []);
  const handleShow = () => {
    setShowModal(true);
    setIsEditMode(false);
    setSelectedExpenseType(null);
    setExpenseType("");
  };
  const handleEdit = (expenseType) => {
    setShowModal(true);
    setIsEditMode(true);
    setSelectedExpenseType(expenseType);
    setExpenseType(expenseType.title);
  };
  const handleClose = () => {
    setShowModal(false);
    setIsEditMode(false);
    setSelectedExpenseType(null);
    setExpenseType("");
  };
  const handleSave = async () => {
    if (expenseType) {
      const data = {
        title: expenseType,
        store: storeId,
        type: "expense type",
      };

      if (isEditMode && selectedExpenseType) {
        // Updating existing expense type
        Put("vendorDepartmentServiceData", selectedExpenseType.id, data)
          .then((response) => {
            if (response.status === 200) {
              setExpenseTypes(
                expenseTypes.map((et) =>
                  et.id === selectedExpenseType.id
                    ? { ...et, title: expenseType }
                    : et
                )
              );
              toast.success("Expense type updated successfully!");
            } else {
              console.error("Error updating expense type:", response.data);
              toast.error("Error updating expense type: " + response.data);
            }
            handleClose();
          })
          .catch((error) => {
            toast.error(
              "Error updating expense type: " +
                (error.response ? error.response.data : error.message)
            );
          });
      } else {
        Post("vendorDepartmentServiceData", data)
          .then((response) => {
            if (response.status === 201) {
              setExpenseTypes([...expenseTypes, response.data]);
              toast.success("Expense type added successfully!");
            } else {
              console.error("Error saving expense type:", response.data);
              toast.error("Error saving expense type: " + response.data);
            }
            handleClose();
          })
          .catch((error) => {
            toast.error(
              "Error saving expense type: " +
                (error.response ? error.response.data : error.message)
            );
          });
      }
    } else {
      toast.warn("Expense type is required");
    }
  };
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (!result.isConfirmed) return;

      Delete("vendorDepartmentServiceData", id)
        .then(() => {
          setExpenseTypes(expenseTypes.filter((et) => et.id !== id));
          toast.success("Expense type deleted successfully!");
        })
        .catch((error) => {
          toast.error(
            "Error deleting expense type: " +
              (error.response ? error.response.data : error.message)
          );
        });
    });
  };
  const data = expenseTypes.filter((et) => et.type === "expense type");
  const columns = [{ header: "Department / Expense Type", field: "title" }];
  return (
    <>
      <div className="due-days">
        <div className="header-row">
          <div className="setting-title">Manage Expense Types</div>
          <div className="btn-position">
            <button className="vendor-add-button" onClick={handleShow}>
              + Add Expense Type
            </button>
          </div>
        </div>
        <SettingTable
          data={data}
          showFooter={true}
          columns={columns}
          showAction={true}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
        <ReusableModal
          show={showModal}
          handleClose={handleClose}
          title={isEditMode ? "Edit Expense Type" : "Add Expense Type"}
          width="697px"
          height="171px"
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSave();
            }}
          >
            <div className="form-group d-flex align-items-center ">
              <input
                type="text"
                id="expenseTypeName"
                className="input-field"
                placeholder="Expense Type"
                value={expenseType}
                onChange={(e) => setExpenseType(e.target.value)}
                style={{ width: "483px", marginRight: "10px" }}
              />
              <button
                type="submit"
                style={{
                  padding: "6px 8px",
                  backgroundColor: "#4545db",
                  color: "white",
                  border: "none",
                  borderRadius: "40px",
                  cursor: "pointer",
                  width: "103px",
                  height: "42px",
                  fontSize: "18px",
                  marginLeft: "20px",
                  isEditMode,
                }}
              >
                {isEditMode ? "Update" : "Submit"}
              </button>
            </div>
          </form>
        </ReusableModal>
        <ToastContainer />
      </div>
    </>
  );
}
export default ManageExpenseType;
