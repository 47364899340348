/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line @typescript-eslint/no-unused-vars

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Report.css";
import BusinessReport from "./BusinessReport";
import GasReport from "./GasReport";
import CashDrop from "./CashDrop";
import useApi from "../../../../utils/api-manager/Helper/useApi";

export default function Reports() {
  const [activeTab, setActiveTab] = useState("business report");
  const [tabs, setTabs] = useState([]);
  const { Get } = useApi();
  const navigate = useNavigate(); // Initialize useNavigate
  const location = useLocation(); // Get the location object which contains state
  const { selectedDate } = location.state || {}; // Retrieve selectedDate from state

  useEffect(() => {
    fetchIsActive();
    initializeTabs();

    // Listen for changes in localStorage
    const handleStorageChange = () => {
      initializeTabs();
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const initializeTabs = () => {
    const settings = JSON.parse(localStorage.getItem("settings") || "{}");

    const baseTabs = [
      {
        name: "Business Report",
        content: <BusinessReport isSelectedDate={selectedDate} />,
      },
    ];

    if (settings.gas_report_feature) {
      baseTabs.push({
        name: "Gas Report",
        content: <GasReport isSelectedDate={selectedDate} />,
      });
    }

    if (settings.cash_drops) {
      baseTabs.push({
        name: "Cash Drop",
        content: <CashDrop />,
      });
    }

    setTabs(baseTabs);
  };

  const handleTabClick = (tab) => {
    if (!tab.disabled) {
      setActiveTab(tab.name.toLowerCase());
    }
  };

  const fetchIsActive = () => {
    Get("isShiftActive")
      .then((response) => {
        if (
          response.data.message === "You already have an active shift" &&
          response.data.shift === "1_shift"
        ) {
          localStorage.setItem(
            "isShiftActiveData",
            JSON.parse(response.data.shift_id)
          );

          navigate("/reportDateSelection");
        }
      })
      .catch((error) => {
        console.error("Error fetching shift status:", error);
      });
  };

  return (
    <div className="report-container">
      <div className="tab-view">
        <ul className="nav report-tab">
          {tabs.map((tab) => (
            <li className="nav-item" key={tab.name}>
              <a
                className={`nav-link ${
                  activeTab === tab.name.toLowerCase() ? "active" : ""
                } ${tab.disabled ? "disabled" : ""}`}
                href="#"
                onClick={() => handleTabClick(tab)}
                aria-disabled={tab.disabled}
              >
                {tab.name}
              </a>
            </li>
          ))}
        </ul>
        <div className="tab-content mt-4">
          {tabs.map((tab) =>
            activeTab === tab.name.toLowerCase() && !tab.disabled ? (
              <div key={tab.name} className="tab-pane active">
                {tab.content}
              </div>
            ) : null
          )}
        </div>
      </div>
    </div>
  );
}
