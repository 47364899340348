import React from 'react';

export default function BusinessReportTab({
  registerNetSales = [],
  savedShiftDetails = {},
  totalNetSales,
  totalNetTaxes,
  checkedStates,
  mealTaxValue,
  grossSales,
  refund,
  cheque,
  creditCard,
  totalRegisterCash,
  cashToAccount,
  overShort,
  setShiftDetails,
  handleGlobalField,
  setMealTaxValue,
  renderRegisterInputs,
}) {
  return (
    <div>
      <div className="row business-report">
        <div className="col-md-12">
          {registerNetSales && registerNetSales.length > 0 ? (
            renderRegisterInputs(registerNetSales, "net_sales" || `${savedShiftDetails}`, "Net Sales Register")
          ) : (
            <div>No register data available</div>
          )}

          {registerNetSales.length > 0 ? (
            renderRegisterInputs(registerNetSales, "net_taxes", "Net Taxes")
          ) : (
            <div className="data-report">No register data available</div>
          )}

          <div className="data-report">
            <label htmlFor="total_net_sales" className="label">Total Net Sales</label>
            <input
              style={{ background: "#F4F4F470", border: "none" }}
              type="text"
              step="any"
              id="total_net_sales"
              name="total_net_sales"
              className="input"
              value={totalNetSales}
              readOnly
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .slice(0, 12);
              }}
            />
          </div>

          <div className="data-report">
            <label htmlFor="total_net_taxes" className="label">Total Net Taxes</label>
            <input
              style={{ background: "#F4F4F470", border: "none" }}
              type="text"
              step="any"
              id="total_net_taxes"
              name="total_net_taxes"
              className="input"
              value={totalNetTaxes}
              readOnly
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .slice(0, 12);
              }}
            />
          </div>

          <div className="hr" style={{ width: "500px",maxWidth:"100%" }}>
            <hr />
          </div>

          <div className="data-report">
            {checkedStates.meal_tax ? (
              <>
                <label htmlFor="meal_tax" className="label">Meal Tax</label>
                <input
                  type="text"
                  step="any"
                  id="meal_tax"
                  name="meal_tax"
                  className="input-readonly"
                  value={mealTaxValue === "N/A" ? "" : mealTaxValue}
                  onChange={(e) => {
                    const value = e.target.value;
                    setMealTaxValue(value);
                    setShiftDetails((prevDetails) => ({
                      ...prevDetails,
                      business_report: {
                        ...prevDetails.business_report,
                        meal_tax: value,
                      },
                    }));
                  }}
                  onBlur={(e) => handleGlobalField("meal_tax", e.target.value)}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9.]/g, "")
                      .slice(0, 12);
                  }}
                />
              </>
            ) : (
              <div>No register data available</div>
            )}
          </div>

          <div className="data-report">
            <label htmlFor="gross_sales" className="label">Gross Sales</label>
            <input
              type="text"
              step="any"
              id="gross_sales"
              name="gross_sales"
              className="input-readonly"
              value={grossSales}
              readOnly
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .slice(0, 12);
              }}
            />
          </div>

          <div className="data-report">
            <label htmlFor="refund" className="label">Refund</label>
            <input
              type="text"
              step="any"
              id="refund"
              name="refund"
              className="input"
              value={refund === "N/A" ? "" : refund}
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .slice(0, 12);
              }}
              onChange={(e) => {
                setShiftDetails((prevDetails) => ({
                  ...prevDetails,
                  business_report: {
                    ...prevDetails.business_report,
                    refund: e.target.value,
                  },
                }));
              }}
              onBlur={(e) => handleGlobalField("refund", e.target.value)}
            />
          </div>

          <div className="data-report">
            <label htmlFor="cheque" className="label">Cheque</label>
            <input
              type="text"
              step="any"
              id="cheque"
              name="cheque"
              className="input"
              value={cheque === "N/A" ? "" : cheque}
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .slice(0, 12);
              }}
              onChange={(e) => {
                setShiftDetails((prevDetails) => ({
                  ...prevDetails,
                  business_report: {
                    ...prevDetails.business_report,
                    cheque: e.target.value,
                  },
                }));
              }}
              onBlur={(e) => handleGlobalField("cheque", e.target.value)}
            />
          </div>

          <div className="data-report">
            <label htmlFor="credit_card" className="label">Credit Card</label>
            <input
              type="text"
              step="any"
              id="credit_card"
              name="credit_card"
              className="input"
              value={creditCard === "N/A" ? "" : creditCard}
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .slice(0, 12);
              }}
              onChange={(e) => {
                setShiftDetails((prevDetails) => ({
                  ...prevDetails,
                  business_report: {
                    ...prevDetails.business_report,
                    credit_card: e.target.value,
                  },
                }));
              }}
              onBlur={(e) => handleGlobalField("credit_card", e.target.value)}
            />
          </div>

          {registerNetSales.length > 0 ? (
            renderRegisterInputs(registerNetSales, "register_amount", "Register Cash")
          ) : (
            <div className="data-report">No register data available</div>
          )}

          <div className="data-report">
            <label htmlFor="total_register_cash" className="label">Total Register Cash</label>
            <input
              type="text"
              step="any"
              id="total_register_cash"
              name="total_register_cash"
              className="input-readonly"
              value={totalRegisterCash}
              readOnly
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .slice(0, 12);
              }}
            />
          </div>

          <div className="data-report">
            <label htmlFor="cash_to_account" className="label">Cash to Account</label>
            <input
              type="text"
              step="any"
              id="cash_to_account"
              name="cash_to_account"
              className="input-readonly"
              value={cashToAccount === "N/A" ? "" : cashToAccount}
              onChange={(e) => {
                setShiftDetails((prevDetails) => ({
                  ...prevDetails,
                  business_report: {
                    ...prevDetails.business_report,
                    cash_to_account: e.target.value,
                  },
                }));
              }}
              onBlur={(e) => handleGlobalField("cash_to_account", e.target.value)}
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .slice(0, 12);
              }}
              readOnly
            />
          </div>

          <div className="data-report">
            <label htmlFor="over_short" className="label">Over Short</label>
            <input
              type="text"
              step="any"
              id="over_short"
              name="over_short"
              className="input-readonly"
              value={overShort}
              onChange={(e) => handleGlobalField("over_short", e.target.value)}
              readOnly
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, "")
                  .slice(0, 12);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
