/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import ReusableModal from "../ManageSettings/ReusableModal"; // Adjust the path as needed
import { toast } from "react-toastify";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import AuthContext from "../../../../utils/secure-route/AuthContext";

function ManageGameModal({ show, handleClose, gameNo, bookNo, makeBlank,fetchGameData }) {
  const [gameName, setGameName] = useState("");
  const [gameNumber, setGameNumber] = useState(gameNo);
  const [totalTickets, setTotalTickets] = useState("");
  const [gamePrice, setGamePrice] = useState("");
  const { user } = useContext(AuthContext);
  const [bookNumber, setBookNumber] = useState(bookNo);
  const [games, setGames] = useState([]);
  const storeId = user.store;
  const { Post } = useApi();

  const makeCurrentBlank = () => {
    setGameName("");
    setGameNumber(""); 
    setTotalTickets("");
    setGamePrice("");
    setBookNumber("");
  };

  useEffect(() => {
    if (show && gameNo) {
      setGameNumber(gameNo);
      setBookNumber(bookNo)
    } else if (!show) {
      setGameNumber(""); // Clear when modal closes
      setBookNumber("")
    }
  }, [show, gameNo]);
  // Handle form submission for adding a new game
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    const todayDate = new Date(
      new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
    );
      const day = String(todayDate.getDate()).padStart(2, "0");
    const month = String(todayDate.getMonth() + 1).padStart(2, "0");
    const year = todayDate.getFullYear();
    const today = `${year}-${month}-${day}`;
  
      const gameData = {
      game_name: gameName,
      game_no: gameNumber, 
      total_no_of_tickets: totalTickets,
      value: gamePrice,
      store: storeId,
      receive_date: today,
    };

    await Post("lotteryGameData", gameData)
      .then(async (response) => {
     
        const newGame = {
          inventory_date: today,
          lottery_game: response.data.id,
          book_no: bookNumber,
          game_name: gameName,
          store: storeId,
          scan_no: "Manual Entry",
        };
        await Post("lotteryInventoryData", newGame)
          .then((resp) => {
            toast.success("Game added successfully");
            makeBlank();
          })
          .catch((err) => {
            makeBlank();
            console.error(err);
            toast.error("Unable to add game.");
          });

        setGames((prevGames) => [...prevGames, response.data]);
        handleClose();
        setGameNumber("");
        fetchGameData();
      })
      .catch((error) => {
        handleClose();
        console.error("Error adding game:", error);
        toast.error("Error adding game.");
      })
      .finally(() => {
        makeBlank();
        makeCurrentBlank();
      });
  };
  return (
    <ReusableModal
      show={show}
      handleClose={handleClose}
      title="Add New Game"
      width="620px"
    >
      <form onSubmit={handleSubmit}>
        <div className="form-group d-flex flex-column">
          <div className="d-flex align-items-center mb-3">
            <input
              type="text"
              className="input-field"
              placeholder="Game Name"
              value={gameName}
              onChange={(e) => setGameName(e.target.value)}
              style={{ width: "270px", marginRight: "10px" }}
              required
            />
            <input
              type="text"
              className="input-field"
              placeholder="Game Number"
              name="game_no"
              value={gameNumber}
              onChange={(e) => setGameNumber(e.target.value)}
              style={{ width: "270px", marginRight: "10px" }}
              required
            />
          </div>
          <div className="d-flex align-items-center mb-3">
            <input
              type="number"
              className="input-field"
              placeholder="Total Tickets"
              value={totalTickets}
              onChange={(e) => setTotalTickets(e.target.value)}
              style={{ width: "270px", marginRight: "10px" }}
              required
            />
            <input
              type="number"
              className="input-field"
              placeholder="Game Price"
              value={gamePrice}
              onChange={(e) => setGamePrice(e.target.value)}
              style={{ width: "270px", marginRight: "10px" }}
              required
            />
          </div>
          <div className="d-flex align-items-center mb-3">
            <input
              type="number"
              className="input-field"
              placeholder="Book Number"
              value={bookNumber}
              onChange={(e) => setBookNumber(e.target.value)}
              style={{ width: "270px", marginRight: "10px" }}
              required
            />
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              style={{
                marginRight: "40px",
                padding: "4px 20px",
                backgroundColor: "#4545db",
                color: "white",
                border: "none",
                borderRadius: "40px",
                cursor: "pointer",
                width: "auto",
                height: "42px",
                fontSize: "18px",
              }}
              type="submit"
            >
              Add Game & Book
            </button>
          </div>
        </div>
      </form>
    </ReusableModal>
  );
}

export default ManageGameModal;
