/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import "./Settings.css";
import { toast, ToastContainer } from "react-toastify";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import AuthContext from "../../../../utils/secure-route/AuthContext";
import Swal from "sweetalert2";

function ManageDepartments() {
  const [departments, setDepartments] = useState([]);
  const [newDepartment, setNewDepartment] = useState("");
  const [editDepartmentId, setEditDepartmentId] = useState(null);
  const [editDepartmentTitle, setEditDepartmentTitle] = useState("");
  let { user } = useContext(AuthContext);
  const storeId = user.store;
  const { Get, Post, Put, Delete } = useApi();
  useEffect(() => {
    Get("vendorDepartmentServiceData")
      .then((response) => {
        setDepartments(response);
      })
      .catch((error) => {
        console.error("Error fetching departments:", error);
        toast.error("Error fetching departments.");
      });
  }, []);
  const handleSaveDepartment = async () => {
    const departmentData = {
      store: storeId,
      type: "department",
      title: newDepartment || editDepartmentTitle,
    };

    if (editDepartmentId) {
      Put("vendorDepartmentServiceData", editDepartmentId, departmentData)
        .then(() => {
          setDepartments((prevDepartments) =>
            prevDepartments.map((dept) =>
              dept.id === editDepartmentId
                ? { ...dept, title: departmentData.title }
                : dept
            )
          );
          resetInputFields();
          toast.success("Department updated successfully!");
        })
        .catch((error) => {
          console.error("Error editing department:", error);
          toast.error("Department Type Is Required");
        });
    } else {
      Post("vendorDepartmentServiceData", departmentData)
        .then((response) => {
          if (response.status === 201) {
            setDepartments((prevDepartments) => [
              ...prevDepartments,
              response.data,
            ]);
            toast.success("Department added successfully!");
          } else {
            toast.error("Something went wrong!");
          }
          setNewDepartment("");
        })
        .catch((error) => {
          console.error("Error adding department:", error);
          toast.error("Department Type Is Required");
        });
    }
  };

  const resetInputFields = () => {
    setEditDepartmentId(null);
    setEditDepartmentTitle("");
    setNewDepartment("");
  };
  const handleDeleteDepartment = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (!result.isConfirmed) return;

      Delete("vendorDepartmentServiceData", id)
        .then(() => {
          setDepartments(departments.filter((dept) => dept.id !== id));
          resetInputFields();
          toast.success("Department deleted successfully!");
        })
        .catch((error) => {
          console.error(
            "Error deleting department:",
            error.response ? error.response.data : error.message
          );
          toast.error("Error deleting department.");
        });
    });
  };
  const filteredDepartments = departments.filter(
    (dept) => dept.type === "department"
  );
  return (
    <div className="store-container">
      <ToastContainer />
      <p className="setting-title">Manage Departments</p>
      <div className="create-department-section">
        <div className="input-container">
          <div className="input-row">
            <input
              type="text"
              placeholder="New Department"
              className="input-field"
              value={editDepartmentId ? editDepartmentTitle : newDepartment}
              onChange={(e) => {
                if (editDepartmentId) {
                  setEditDepartmentTitle(e.target.value);
                } else {
                  setNewDepartment(e.target.value);
                }
              }}
              required
            />
            <button className="dept-button" onClick={handleSaveDepartment}>
              {editDepartmentId ? "Update" : " + Add"}
            </button>
          </div>
        </div>
      </div>
      <div className="show-department-section">
        <div className="dept-cards-row">
          {filteredDepartments.length === 0 ? (
            <p
              style={{
                textAlign: "center",
                fontFamily: "Manrope",
                fontWeight: "600",
                fontSize: "40px",
                background:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0.1) -92.86%, #4545DB 71.43%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              No Data Found
            </p>
          ) : (
            filteredDepartments.map((dept) => (
              <div className="dept-card" key={dept.id}>
                {editDepartmentId === dept.id ? (
                  <input
                    className="dept-info"
                    value={editDepartmentTitle}
                    onChange={(e) => setEditDepartmentTitle(e.target.value)}
                  />
                ) : (
                  <input className="dept-info" value={dept.title} readOnly />
                )}
                <svg
                  className="dept-icon"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    if (editDepartmentId === dept.id) {
                      resetInputFields();
                    } else {
                      setEditDepartmentId(dept.id);
                      setEditDepartmentTitle(dept.title);
                      setNewDepartment("");
                    }
                  }}
                >
                  <path
                    d="M13.5854 4.695C13.8304 4.435 13.8304 4.00167 13.5854 3.755L12.1154 2.195C11.8829 1.935 11.4746 1.935 11.2296 2.195L10.0736 3.415L12.4295 5.915M2.45947 11.5017V14.0017H4.81534L11.7636 6.62167L9.4077 4.12167L2.45947 11.5017Z"
                    fill="#002300"
                  />
                </svg>
                <svg
                  className="dept-icon"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleDeleteDepartment(dept.id)}
                >
                  <path
                    d="M3.84503 12.6667C3.84503 13.4 4.41044 14 5.10149 14H10.1273C10.8184 14 11.3838 13.4 11.3838 12.6667V6C11.3838 5.26667 10.8184 4.66667 10.1273 4.66667H5.10149C4.41044 4.66667 3.84503 5.26667 3.84503 6V12.6667ZM11.3838 2.66667H9.81322L9.36718 2.19333C9.25409 2.07333 9.09075 2 8.92741 2H6.30141C6.13807 2 5.97473 2.07333 5.86165 2.19333L5.4156 2.66667H3.84503C3.4995 2.66667 3.2168 2.96667 3.2168 3.33333C3.2168 3.7 3.4995 4 3.84503 4H11.3838C11.7293 4 12.012 3.7 12.012 3.33333C12.012 2.96667 11.7293 2.66667 11.3838 2.66667Z"
                    fill="#002300"
                  />
                </svg>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}
export default ManageDepartments;
