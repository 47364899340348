import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../../config/Global.json";
import CustomInput from "../../custominput/CustomInput";

const base64Decode = (encodedStr) => {
  try {
    if (encodedStr) {
      return atob(encodedStr.replace(/_/g, "/").replace(/-/g, "+"));
    }
  } catch (e) {
    console.error("Error decoding Base64 string", e);
    return "";
  }
};

const CreateNewPassword = () => {
  const { temp_token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [firstName, setFirstName] = useState("");
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const parts = temp_token.split(".");
    setToken(parts[0]);
    setFirstName(base64Decode(parts[1]));
    setErrors({});
  }, [temp_token]);

  const checkValidPassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      return {
        password:
          "Password must be at least 8 characters long, and include at least one digit, one uppercase letter, and one special character.",
      };
    }
    return {};
  };

  const handleBlur = (field) => {
    let validationErrors = {};
    if (field === "newPassword") {
      validationErrors = checkValidPassword(newPassword);
    }
    if (field === "confirmPassword" && newPassword !== confirmPassword) {
      validationErrors.confirmPassword = "Passwords do not match.";
    }
    setErrors((prev) => ({ ...prev, ...validationErrors }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiBaseUrl = config.api.host;
    const confirmPasswordEndpoint = config.api.confirmPassword;

    if (Object.keys(errors).length > 0 || newPassword !== confirmPassword) {
      return;
    }

    try {
      const url = `${apiBaseUrl}${confirmPasswordEndpoint}${token}/`;
      await axios.post(url, { new_password: newPassword });
      toast.success("Password has been reset successfully");
      navigate("/success-msg");
    } catch (error) {
      toast.error("Failed to reset password");
    }
  };

  return (
    <div className="container" style={{maxWidth: "500px" }}>
      <div className="row justify-content-center align-items-center">
        <p
          className="form-title mb-3 mt-4"
          
        >
          Create New Password
        </p>
        <p
          className="info-message mb-4"
          style={{ fontSize: "18px", color: "#002300" }}
        >
          Hi <strong>{firstName ? firstName : ""}</strong>, secure your ledger by setting a new password and regain full access to your financial data.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <CustomInput
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              onBlur={() => handleBlur("newPassword")}
            />
            {errors.password && <div className="text-danger">{errors.password}</div>}
          </div>
          <div className="mb-3">
            <CustomInput
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onBlur={() => handleBlur("confirmPassword")}
            />
            {errors.confirmPassword && <div className="text-danger">{errors.confirmPassword}</div>}
          </div>
          <button
            type="submit"
            className="btn btn-primary rounded-pill"
            style={{
              width: "100%",
              height: "60px",
              backgroundColor: "#4545DB",
              color: "#ffffff",
              padding: "20px 40px",
              fontFamily: "Manrope, sans-serif",
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "20px",
              border: "none",
              cursor: "pointer",
              outline: "none",
              boxShadow: "none",
            }}
            disabled={Object.keys(errors).length > 0 || newPassword !== confirmPassword}
          >
            SUBMIT
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateNewPassword;
