/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { MoreVert } from '@mui/icons-material';
import useApi from '../../../../../utils/api-manager/Helper/useApi';
import StoreCashTable from './StoreCashTable';

const StoreCashBalance = () => {
  const [showTable, setShowTable] = useState(false);
  const [storeData, setStoreData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [otherStoreCash, setOtherStoreCash] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { Get } = useApi();

  // Add window resize listener
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  useEffect(() => {
    const fetchDashboardCashBalance = async () => {
      try {
        setLoading(true);
        const response = await Get('dashboardCashBalance');
        setStoreData(response);
        setOtherStoreCash(response.additional_stores);
      } catch (err) {
        setError('Failed to fetch data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardCashBalance();
  }, []);

  // Responsive styles
  const containerStyle = {
    maxWidth: '513px',
    width: windowWidth < 768 ? '100%' : windowWidth < 992 ? '100%' : 'auto', // Full width for screens < 768 and < 992
    height: windowWidth < 576 ? 'auto' : '250px',
    minHeight: '200px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: windowWidth < 576 ? '12px' : '16px',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    marginLeft: windowWidth < 992 ? 'auto' : '0', // Centering the div on < 992px
    marginRight: windowWidth < 992 ? 'auto' : '0', // Centering the div on < 992px
    display: windowWidth < 992 ? 'block' : 'block', // Block display to center on smaller screens
  };
  

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: windowWidth < 576 ? '8px' : '16px',
  };

  const titleStyle = {
    fontWeight: 'bold',
    whiteSpace: windowWidth < 480 ? 'normal' : 'nowrap',
    fontSize: windowWidth < 576 ? '16px' : '18px',
    margin: 0,
  };

  const usdAmountStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f1f3f4',
    borderRadius: '8px',
    padding: windowWidth < 576 ? '8px' : '10px',
    marginTop: '0px',
  };

  const storeInfoStyle = {
    marginTop: windowWidth < 576 ? '8px' : '16px',
    backgroundColor: '#f1f3f4',
    borderRadius: '8px',
    padding: windowWidth < 576 ? '8px' : '10px',
  };

  const storeNameStyle = {
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: windowWidth < 576 ? '14px' : '16px',
    margin: '0 0 8px 0',
  };

  const storeDetailsStyle = {
    display: 'flex',
    flexDirection: windowWidth < 480 ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: windowWidth < 480 ? 'flex-start' : 'center',
  };

  const addressStyle = {
    color: '#757575',
    textAlign: 'left',
    fontSize: windowWidth < 576 ? '12px' : '14px',
    margin: windowWidth < 480 ? '0 0 8px 0' : 0,
  };

  const cashAmountStyle = {
    fontWeight: 'bold',
    color: 'black',
    textAlign: windowWidth < 480 ? 'left' : 'right',
    marginBottom: windowWidth < 480 ? '0' : '20px',
    fontSize: windowWidth < 576 ? '14px' : '16px',
  };

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        <h6 style={titleStyle}>
          Current Store Cash {windowWidth >= 480 && <br />}
          Balance
        </h6>
      </div>

      <div>
        <div style={usdAmountStyle}>
          <span style={{ fontWeight: 'bold', fontSize: windowWidth < 576 ? '14px' : '16px' }}>USD Amount</span>
          <button style={{ background: 'none', border: 'none', color: 'gray', cursor: 'pointer' }}>
            <MoreVert fontSize={windowWidth < 576 ? 'small' : 'medium'} />
          </button>
        </div>

        <div style={storeInfoStyle}>
          {loading ? (
            <p style={{ fontSize: windowWidth < 576 ? '14px' : '16px' }}>Loading...</p>
          ) : error ? (
            <p style={{ color: 'red', fontSize: windowWidth < 576 ? '14px' : '16px' }}>{error}</p>
          ) : (
            <>
              <h6 style={storeNameStyle}>
                {storeData?.store_name || 'N/A'}
              </h6>

              <div style={storeDetailsStyle}>
                <p style={addressStyle}>
                  {storeData?.address || 'N/A'}
                </p>

                <h6 style={cashAmountStyle}>
                  ${storeData?.total_current_cash?.toLocaleString() || '0.00'}
                </h6>
              </div>
            </>
          )}
        </div>
      </div>

      <StoreCashTable showTable={showTable} otherStoreCash={otherStoreCash} />
    </div>
  );
};

export default StoreCashBalance;