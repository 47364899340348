/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import AuthContext from "../../.././../utils/secure-route/AuthContext";
import SettingTable from "../ManageSettings/SettingTable";
import "./GeneralLedger.css";
import Swal from "sweetalert2";


export default function GeneralLedgerOtherIncome() {
  const { user } = useContext(AuthContext);
  const { Get, Post, Delete, Put } = useApi();
  const storeid = user.store;
  const [banks, setBanks] = useState();
  const [isEditing, setIsEditing] = useState(false); // Track if editing
  const [currentRecord, setCurrentRecord] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [otherIncomeType, setOtherIncomeType] = useState();

  const toggleModal = () => setShowModal(!showModal);
  const [otherIncome, setOtherIncome] = useState();

  const handleToggleModal = () => {
    if (showModal) {
      // Reset form data and editing state when closing modal
      setFormData({
        store: storeid,
        sr_no: "",
        date: today,
        transaction_type: "Other Income",
        bank: "",
        amount: "",

        cash_amount: "",
        cheque_amount: "",
        total_amount: "",
        note: "",
        owner: null,
        income_type: "",
        income_type_title: "",
      });
      setIsEditing(false); // Reset to not editing
    }
    setShowModal(!showModal);
  };
  const handleAddOtherIncome = async () => {
    try {
      // Fetch other income types when the button is clicked
      await fetchOtherIncomeType();
      await fetchBanks();
    } catch (error) {
      console.error("Error fetching other income types:", error);
    }

    // Reset editing state and form data
    setIsEditing(false);
    setFormData({
      store: storeid,
      sr_no: "",
      date: today,
      transaction_type: "Other Income",
      bank: "",
      amount: "",
      cash_amount: "",
      cheque_amount: "",
      total_amount: "",
      note: "",
      owner: null,
      income_type: "",
      income_type_title: "",
    });

    // Open the modal
    setShowModal(true);
  };

  useEffect(() => {
    fetchOtherIncomeData();
  }, []);


  const fetchOtherIncomeData = async () => {
    try {
      const otherIncomeResponse = await Get("transactionData");
      setOtherIncome(filterByTransactionType(otherIncomeResponse, "Other Income"));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  function filterByTransactionType(dataArray, transactionType) {
    return dataArray.filter(record => record.transaction_type === transactionType);
  }
  const fetchBanks = async () => {
    try {
      const data = await Get("bank");
      setBanks(data);
    } catch (error) {
      console.error("Error fetching banks:", error);
    }
  };
  const fetchOtherIncomeType = async () => {
    try {
      const data = await Get("otherIncomeData");
      setOtherIncomeType(data);
    } catch (error) {
      console.error("Error fetching banks:", error);
    }
  };
  const handleDateChange = (e) => {
    const { value } = e.target;

    // Ensure the date value is properly formatted to YYYY-MM-DD
    setFormData((prevData) => ({
      ...prevData,
      date: value, // This will be in the correct format for the <input type="date">
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'amount') {
      // Allow only numeric characters and limit to 10 digits
      const numericValue = value.replace(/[^0-9]/g, '').slice(0, 10);
      setFormData((prevData) => ({
        ...prevData,
        [name]: numericValue,
      }));
    } else {
      // For other fields, handle normally (no validation)
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleIncomeChange = (e) => {
    const { name, value } = e.target;

    if (name === "income_type_title") {
      const selectedIncome = otherIncomeType?.find(
        (income) => income.income_type_title === value
      );

      // If no match is found, log a message to debug and set a default value for income_type
      if (!selectedIncome) {
        console.warn(`Income type for "${value}" not found.`);
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        income_type: selectedIncome ? selectedIncome.income_type : "", // Ensure fallback to empty string if not found
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const todayDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
  );
    const day = String(todayDate.getDate()).padStart(2, "0");
  const month = String(todayDate.getMonth() + 1).padStart(2, "0");
  const year = todayDate.getFullYear();
  const today = `${year}-${month}-${day}`;
  
  const [formData, setFormData] = useState({
    store: storeid,
    sr_no: "",
    date: today, // default to today
    transaction_type: "Other Income",
    bank: "",
    amount: "",
    cash_amount: "",
    cheque_amount: "",

    total_amount: "",
    note: "",
    owner: null,
    income_type: "", // Initialize as empty string
    income_type_title: "", // Initialize as empty string
  });

  const handleOtherIncome = async (event) => {
    event.preventDefault();
    const { sr_no, bank_deposite_type, ...updatedFormData } = formData;

    updatedFormData.other_income = updatedFormData.income_type_title;
    updatedFormData.pay_method_status = null;


    const cleanedFormData = Object.fromEntries(
      Object.entries(updatedFormData).filter(
        ([key, value]) =>
          value !== "" && value !== null || key === "pay_method_status"
      )
    );

    if (!formData.amount) {
      toast.error("Please enter amount")
      return;
    }
    if (formData.pay_method === "cheque") {
      if (!formData.bank) {
        toast.error("Please select a Bank.");
        return;
      }
      if (!formData.cheque_no) {
        toast.error("Please enter cheque no");
        return;
      }

    }
    if (formData.pay_method === "bank") {
      if (!formData.bank) {
        toast.error("Please select a Bank.");
        return;
      }
    }

    try {
      let response;
      if (isEditing) {
        response = await Put("transactionData", currentRecord.id, updatedFormData);
        toast.success("Other Income updated successfully!");
      } else {
        response = await Post("transactionData", updatedFormData);
        toast.success("Other Income added successfully!");
      }

      if (response && (response.status === 200 || response.status === 201)) {
        setShowModal(false);
        fetchOtherIncomeData();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Submission failed: " + error.message);
    }
  };


  // Edit handler remains unchanged
  const handleEditOtherIncome = (record) => {
    setIsEditing(true);
    setCurrentRecord(record);
    setFormData({
      ...record,
      date: record.date,
      bank: record.bank || "",
      income_type_title: record.other_income || "",
      other_commission: record.other_commission || "",
      note: record.note || "",
    });
    setShowModal(true); // Open the modal for editing
  };

  const handleDeleteOtherIncome = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
  });

  // Check if the user clicked the confirm button
  if (!result.isConfirmed) return; // Exit if the user cancels
    try {
      await Delete("transactionData", id); // Call delete API with the gas type ID
      setOtherIncome((prevotherIncome) =>
        prevotherIncome.filter((otherincome) => otherincome.id !== id)
      );
      toast.success("other income deleted successfully");
    } catch (error) {
      console.error("Error deleting other Income:", error);
      toast.error("Error deleting other Income");
    }
  };
  const columns = [
    { header: "SR", field: "sr_no" },
    { header: "Date", field: "date" },
    { header: "Amount", field: "amount" },
    { header: "Type", field: "other_income" },
    { header: "Payment Type", field: "pay_method" },
    { header: "Note", field: "note" },
  ];

  return (
    <>
      <ToastContainer />
      <div className="due-days">
        <div className="header-row mb-4">
          <div className="setting-title">Other Income</div>
          <button
            className="gas-invoice-add-button-bank-deposit"
            onClick={handleAddOtherIncome}
            style={{ Width: "200px" }}
          >
            + Add Other Income
          </button>
        </div>

        {showModal && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0,0,0,0.5)",
              zIndex: 1000,
            }}
            onClick={handleToggleModal}
          >
            <div
              onClick={(e) => e.stopPropagation()}
              style={{
                width: "700px",
                backgroundColor: "white",
                margin: "100px auto",
                // padding: "20px",
                borderRadius: "8px",
                position: "relative",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="modal-header" style={{ border: "none" }}>
                <h5 className="modal-title p-2"
                  style={{ fontWeight: "bold" }}>
                  {isEditing ? "Edit Other Income" : "Add Other Income"}
                </h5>


                <svg
                  onClick={toggleModal}
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    marginRight: "10px",
                    marginBottom: '10px',
                    cursor: "pointer",
                  }}
                >
                  <path
                    d="M1 1L13 13M13 1L1 13"
                    stroke="black"
                    stroke-linecap="round"
                  />
                </svg>

                {/* <button
                type="button"
                className="modal-close-btn"
                style={{ border: "none", backgroundColor: "transparent", fontSize:'32px' }}
                onClick={toggleModal}
              >

                <span className="closebtn">&times;</span>
              </button> */}

              </div>
              <div className="modal-body">
                <form onSubmit={handleOtherIncome}>
                  <div className="form-row form-group col-md-4 position-relative">
                    <input
                      style={{
                        backgroundColor: "#0023000C",
                        borderRadius: "10px",
                        height: "52px",
                      }}
                      type="date"
                      className="form-control"
                      name="date"
                      placeholder="Bill Date"
                      value={formData.date}
                      onChange={handleDateChange}
                      required
                    />
                  </div>

                  <div className="mb-3 mt-2 col-md-5">
                    <select
                      style={{
                        backgroundColor: "#0023000C",
                        borderRadius: "10px",
                        height: "52px",
                      }}
                      className="form-control"
                      name="income_type_title"
                      value={formData.income_type_title} // Prefilled value
                      onChange={handleIncomeChange}
                      required
                    >
                      <option value="">Select Income Type</option>
                      {otherIncomeType.length > 0 &&
                        otherIncomeType.map((otherincome) => (
                          <option
                            key={otherincome.id}
                            value={otherincome.income_type_title}
                          >
                            {otherincome.title}
                          </option>
                        ))}
                    </select>


                  </div>
                  <div className="d-flex col-md-12 p-1">
                    <div className="form-check me-3">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="pay_method"
                        value="cash"
                        checked={formData.pay_method === "cash"}
                        onChange={handleChange}
                      />
                      <label className="form-check-label">Cash</label>
                    </div>
                    <div className="form-check me-3">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="pay_method"
                        value="cheque"
                        checked={formData.pay_method === "cheque"}
                        onChange={handleChange}
                      />
                      <label className="form-check-label">Cheque</label>
                    </div>
                    <div className="form-check">
                      <input
                        required
                        type="radio"
                        className="form-check-input"
                        name="pay_method"
                        value="bank"
                        checked={formData.pay_method === "bank"}
                        onChange={handleChange}
                      />
                      <label className="form-check-label">
                        Bank Card (ACH/EFT)
                      </label>
                    </div>
                  </div>

                  <div className="row">
                    {/* Bank dropdown - only visible for Bank Card or Cheque */}
                    {(formData.pay_method === "bank" ||
                      formData.pay_method === "cheque") && (
                        <div className="mb-3 mt-2 col-md-4">
                          <select
                            style={{
                              backgroundColor: "#0023000C",
                              borderRadius: "10px",
                              height: "52px",
                            }}
                            className="form-control"
                            name="bank"
                            value={formData.bank}
                            onChange={handleChange}
                          // required
                          >
                            <option value="">Select a bank</option>
                            {banks.map((bank) => (
                              <option key={bank.id} value={bank.id}>
                                {bank.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}

                    {/* Cheque number input - only visible for Cheque */}
                    {formData.pay_method === "cheque" && (
                      <div className="mb-3 mt-2 col-md-4">
                        <input
                          style={{
                            backgroundColor: "#0023000C",
                            borderRadius: "10px",
                            height: "52px",
                            border: '1px lightgray solid',

                          }}
                          type="text"
                          name="cheque_no"
                          value={formData.cheque_no || ""} // Ensure it's controlled
                          placeholder="Cheque No"
                          onChange={handleChange}
                        />
                      </div>
                    )}
                  </div>
                  <div className="form-row d-flex col-md-12">
                    <textarea
                      className="form-control expenformtextarea"
                      style={{ backgroundColor: "#fafbfa", marginTop: "10px" }}
                      id="note"
                      rows="3"
                      placeholder="Notes"
                      name="note"
                      value={formData.note} // Bind the textarea value to formData.note
                      onChange={handleChange} // Update formData.note on change
                    ></textarea>
                  </div>
                  <div className="form-row d-flex col-md-6">
                    <div class="containercolor">
                      <div class="input-group p-2 mt-2" style={{
                        backgroundColor: "#0023000C",
                        borderRadius: "10px",
                        height: "52px",
                        border: '1px lightgray solid'
                      }}>
                        <div
                          className="input-group-prepend"
                          style={{
                            background: "#F5F5F5",
                            height: "100%",
                            borderRadius: "10px 0 0 10px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span className="input-group-text prefixtext"
                            style={{
                              border: "none",
                              fontSize: "14px",
                            }}
                          >USD</span>
                        </div>
                        <input
                          type="text"
                          name="amount"
                          className="form-control sampletext"
                          value={formData.amount}
                          onChange={handleChange}
                          placeholder="Amount"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                          onInput={(e) => {
                            // Allow only numbers and a decimal point with two digits after it
                            e.target.value = e.target.value
                              .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                              .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                              .slice(0, 12); // Limit input length (10 digits + 1 decimal + 2 decimal places)
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer" style={{ border: "none" }}>
                    <button
                      type="button"
                      className="btn btn-secondary-outline"
                      onClick={toggleModal}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        <SettingTable
          data={otherIncome}
          columns={columns}
          showFooter={true}
          showAction={true}
          showExport={true}
          showFilter={false}
          onEdit={handleEditOtherIncome}
          onDelete={handleDeleteOtherIncome}
        />
      </div>
    </>
  );
}
